<template>
  <div>
    <BlueTitle title="Les réalisations" />
    <v-layout row wrap justify-center>
      <v-flex xs12>
        <v-layout row wrap>
          <v-container grid-list-xl>
              <FiltersCard />
          </v-container>
          <v-container grid-list-xl v-if="allSubmissions">
            <v-layout justify-center row wrap>
              <v-flex
                xs12
                sm6
                md3
                v-for="(submission) in currentSubmissions"
                :key="submission.id"
              >
                <ParticipationCard
                  @onimageclicked="openFullscreen(submission)"
                  :participation="submission"
                  :likeable="isLikeAllowed"
                  :liked="getSubmissionLikedFlag(submission.id)"
                />
              </v-flex>
            </v-layout>
            <v-layout justify-center v-if="allSubmissions.length > nb_per_page">
              <v-flex style="display: flex">
                <v-pagination
                  v-model="page"
                  :length="nb_page"
                  :total-visible="10"
                  style="margin: auto"
                ></v-pagination>
              </v-flex>
            </v-layout>
          </v-container>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import ParticipationCard from './ParticipationCard'
import FiltersCard from './FiltersCard'
import BlueTitle from '../BlueTitle'
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  components: {
    ParticipationCard,
    FiltersCard,
    BlueTitle
  },

  data () {
    return {
      page: 1,
      nb_per_page: 16
    }
  },

  computed: {
    ...mapState({
      allSubmissions: state => state.submissions.allSubmissions,
      participationLikes: state => state.submissions.participationLikes
    }),
    ...mapGetters({
      getContest: 'getContest'
    }),

    isLikeAllowed () {
      if (this.getContest) {
        const startDate = new Date(this.getContest.regional_vote_opening_date + 'T00:00')
        const endDate = new Date(this.getContest.regional_vote_closing_date + 'T00:00')
        // To include endDate
        endDate.setDate(endDate.getDate() + 1)
        const date = new Date()
        return startDate <= date && date < endDate
      }
      return false
    },

    currentSubmissions () {
      if (this.allSubmissions && this.allSubmissions.length) {
        return this.allSubmissions.slice(this.nb_per_page * (this.page - 1), this.nb_per_page * this.page)
      }
      return []
    },

    nb_page () {
      if (this.allSubmissions && this.allSubmissions.length) {
        return Math.ceil(this.allSubmissions.length / this.nb_per_page)
      }
      return 1
    }
  },

  methods: {
    ...mapActions({
      fetchParticipationLikes: 'fetchParticipationLikes'
    }),
    openFullscreen (submission) {
      submission.from = 'all'
      this.$emit('onimageclicked', submission)
    },
    getSubmissionLikedFlag (subId) {
      for (const like of this.participationLikes) {
        if (like.submission === subId) {
          return true
        }
      }

      return false
    }
  },

  mounted () {
    if (localStorage.getItem('watty-uuid')) {
      this.fetchParticipationLikes()
    }
  }
}
</script>
