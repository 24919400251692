<template>
  <v-dialog
   dark
   v-model="dialog"
   lazy
  >
    <v-card style="background-color: #007789;">
        <v-card-title class="justify-center">
          <span class="headline">Changer la vignette</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout justify-center wrap>
              <v-flex xs6 sm4>
                <img :src="imageSrc" style="width: 100%" />
                <v-slider
                  v-model="time"
                  step="any"
                  :max="duration"
                  track-color="white"
                ></v-slider>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="white" flat @click="dialog = false">Annuler</v-btn>
          <v-btn color="white" flat @click="setPlayerTime">Mettre cette vignette</v-btn>
        </v-card-actions>
      </v-card>
  </v-dialog>
</template>

<script>
export default {
  model: {
    prop: 'visible',
    event: 'change'
  },

  props: {
    file: {
      type: File,
      required: true
    },
    visible: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      dialog: false,
      imageSrc: null,
      duration: 0,
      time: 0,
      width: 0,
      height: 0,
      video: null
    }
  },

  methods: {
    setPlayerTime () {
      this.dialog = false
      this.$emit('setTime', this.time)
    },
    setImage () {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      this.video.ontimeupdate = () => {
        canvas.width = this.width
        canvas.height = this.height
        ctx.drawImage(this.video, 0, 0, canvas.width, canvas.height)
        canvas.toBlob(blob => {
          this.imageSrc = URL.createObjectURL(blob)
          return this.imageSrc
        }, 'image/jpeg')
      }
      this.video.currentTime = this.time
    },
    setDuration () {
      return new Promise(resolve => {
        this.video = document.createElement('video')
        const reader = new FileReader()
        reader.onloadend = event => {
          this.video.onloadedmetadata = () => {
            this.duration = this.video.duration
            const videoWidth = this.video.videoWidth
            const videoHeight = this.video.videoHeight
            const maxThumbnailWidth = 800
            const maxThumbnailHeight = 600

            if (videoWidth > videoHeight) {
              this.width = Math.min(maxThumbnailWidth, videoWidth)
              this.height = videoHeight * (this.width / videoWidth)
            } else {
              this.height = Math.min(maxThumbnailHeight, videoHeight)
              this.width = videoWidth * (this.height / videoHeight)
            }
            resolve()
          }
          this.video.src = event.target.result
        }
        reader.readAsDataURL(this.file)
      })
    }
  },

  watch: {
    visible (value) {
      this.dialog = value
    },

    dialog () {
      this.$emit('change', this.dialog)
    },

    time () {
      this.setImage()
    }
  },

  mounted () {
    this.setDuration().then(() => {
      this.setImage()
    })
  }
}
</script>

<style lang="scss" scoped>
  @import "../../styles/theme.scss";

  .btn-action { margin-left: auto!important; }

  .headline { color: $primary; }
</style>
