import Vue from 'vue'
import Vuex from 'vuex'

import uploadStore from './store_modules/upload.js'
import validationStore from './store_modules/validation.js'
import submissions from './store_modules/submissions.js'

Vue.use(Vuex)

function sortByName (a, b) {
  return a.name.localeCompare(b.name)
}

export default new Vuex.Store({
  modules: {
    upload: uploadStore,
    validation: validationStore,
    submissions
  },
  state: {
    contest: null,
    rankRegional: null,
    rankNational: null,
    favorite: null,
    regions: null,
    levels: null,
    readCode: '',
    writeCode: ''
  },

  getters: {
    hasContest (state) {
      return state.contest != null
    },

    hasRankRegional (state) {
      return !!state.rankRegional
    },

    hasRankNational (state) {
      return !!state.rankRegional
    },

    hasParticipants (state) {
      return state.regions !== null
    },

    getCollectivities (state) {
      return function (region) {
        for (let i = 0; i < state.regions.length; i++) {
          if (state.regions[i].name === region.name) {
            return state.regions[i].collectivities.sort(sortByName)
          }
        }
        return []
      }
    },

    getTowns (state) {
      return function (region) {
        let result = []

        for (let i = 0; i < state.regions.length; i++) {
          if (state.regions[i].name === region.name) {
            const collectivities = state.regions[i].collectivities
            for (let j = 0; j < collectivities.length; j++) {
              result = result.concat(collectivities[j].communes)
            }
          }
        }
        return result.sort(sortByName)
      }
    },

    getSchools (state) {
      return function (region, collectivity) {
        for (let i = 0; i < state.regions.length; i++) {
          if (state.regions[i].name === region.name) {
            const collectivities = state.regions[i].collectivities
            for (let j = 0; j < collectivities.length; j++) {
              if (collectivities[j].name === collectivity.name) {
                return collectivities[j].schools.sort(sortByName)
              }
            }
          }
        }
        return []
      }
    },

    getSchoolsFromTown (state) {
      return function (region, town) {
        for (let i = 0; i < state.regions.length; i++) {
          if (state.regions[i].name === region.name) {
            const collectivities = state.regions[i].collectivities
            for (let j = 0; j < collectivities.length; j++) {
              const towns = collectivities[j].communes
              for (let k = 0; k < towns.length; k++) {
                if (towns[k].name === town.name) {
                  return towns[k].schools.sort(sortByName)
                }
              }
            }
          }
        }
        return []
      }
    },

    getContest (state) {
      return state.contest
    },

    getRankRegional (state) {
      return state.rankRegional
    },

    getRankNational (state) {
      return state.rankNational
    }
  },

  mutations: {
    setContest (state, response) {
      state.contest = response
    },

    setRankRegional (state, response) {
      state.rankRegional = response
    },

    setRankNational (state, response) {
      state.rankNational = response
    },

    setFavorite (state, response) {
      state.favorite = response
      state.favorite.from = 'favorite'
    },

    setParticipants (state, response) {
      response.regions.sort(sortByName)
      state.regions = response.regions
      state.levels = response.levels
    },

    setReadCode (state, code) {
      state.readCode = code
      localStorage.setItem('readCode', code)
    },

    setWriteCode (state, code) {
      state.writeCode = code
      localStorage.setItem('writeCode', code)
    }
  },

  actions: {
    fetchContests ({ commit, dispatch }) {
      this.axios.get('/contests/last/')
        .then(function (response) {
          commit('setContest', response.data)
          dispatch('fetchParticipants')
        })
        .catch(function () {
          // Do nothing
        })
    },

    fetchRankRegional ({ commit }) {
      this.axios.get('/submissions/winners/regional/?contest=' + this.state.contest.id)
        .then(function (response) {
          commit('setRankRegional', response.data)
        })
        .catch(function (error) {
          throw error
        })
    },

    fetchRankNational ({ commit }) {
      this.axios.get('/submissions/winners/national/?contest=' + this.state.contest.id)
        .then(function (response) {
          commit('setRankNational', response.data)
        })
        .catch(function () {
          // Do nothing
        })
    },
    fetchFavorite ({ commit }) {
      this.axios.get('/submissions/winner/favorite/?contest=' + this.state.contest.id)
        .then(function (response) {
          commit('setFavorite', response.data)
        })
        .catch(function () {
          // Do nothing
        })
    },
    fetchParticipants ({ commit }) {
      this.axios.get('/participants/')
        .then(function (response) {
          commit('setParticipants', response.data)
        })
        .catch(function () {
          // DO nothing
        })
    },

    loadCodes ({ commit, dispatch }, query) {
      let writeCode = localStorage.getItem('writeCode')
      if (query.writeCode) {
        writeCode = query.writeCode
      }

      let readCode = localStorage.getItem('readCode')
      if (query.readCode) {
        readCode = query.readCode
      }

      if (writeCode) {
        commit('setWriteCode', writeCode)
      }

      if (readCode) {
        commit('setReadCode', readCode)
      }

      if (readCode) {
        dispatch('fetchOwnSubmissions')
      }
    }
  }
})
