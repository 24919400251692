<template>
  <div>
    <v-img :src="contest.banner" />
    <v-container grid-list-xs>
      <v-layout row wrap justify-center self-align-start mb-4>
        <v-flex xs6>
          <p class="description">
            Tu participes au programme <span class="bold">Watty à l'école</span> avec ta classe&nbsp;?
          </p>
          <p class="description">
            Alors propose ton oeuvre au concours d'expression artistique ! De <span class="bold">nombreux prix sont à gagner</span>&nbsp;!
            <span class="bold">Réalise une oeuvre de ton choix</span> (texte, dessin, bande dessinée, vidéo, peinture, poésie...) sur le thème.
          </p>
          <v-img :src="contest.slogan_image" height="200" contain/>
        </v-flex>
      </v-layout>
      <v-layout row wrap justify-center>
        <v-flex column xs12 sm2>
        <ContestDateDescription title="Envoie ta réalisation"
                          :nb="1"
                          :date="contestSubmitUntil" />
        </v-flex>
        <v-flex column self-align-center hidden-sm-and-down>
           <img class="arrow" :src="require('../../assets/arrow.png')" />
        </v-flex>
        <v-flex column hidden-md-and-up py-3>
        </v-flex>
        <v-flex column xs12 sm2>
        <ContestDateDescription :title="getRegionnalVoteLabel"
                              :nb="2"
                              :date="contestRegionnalVote"/>
        </v-flex>
        <v-flex column self-align-center hidden-sm-and-down>
          <img class="arrow" :src="require('../../assets/arrow.png')" />
        </v-flex>
        <v-flex column hidden-md-and-up py-3>
        </v-flex>
        <v-flex column xs12 sm2>
        <ContestDateDescription title="Annonce des résultats régionaux"
                              :nb="3"
                              :date="contestRegionalResult"/>
        </v-flex>
        <template v-if="!contest.simplified">
          <v-flex column self-align-center hidden-sm-and-down>
            <img class="arrow" :src="require('../../assets/arrow.png')" />
          </v-flex>
          <v-flex column hidden-md-and-up py-3>
          </v-flex>
          <v-flex column xs12 sm2>
          <ContestDateDescription title="Annonce du gagnant national"
                                :nb="4"
                                :date="contestNationalResult"/>
          </v-flex>
        </template>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import ContestDateDescription from './ContestDateDescription'

const dateFormat = 'D MMMM'
export default {
  components: {
    ContestDateDescription
  },

  props: {
    contest: {
      type: Object,
      required: true
    }
  },

  computed: {
    contestSubmitUntil () {
      return 'Entre le ' + this.$moment(this.contest.upload_opening_date).format(dateFormat) +
        ' et le ' + this.$moment(this.contest.upload_closing_date).format(dateFormat)
    },
    contestRegionnalVote () {
      return 'Entre le ' + this.$moment(this.contest.regional_vote_opening_date).format(dateFormat) +
        ' et le ' + this.$moment(this.contest.regional_vote_closing_date).format(dateFormat)
    },
    contestRegionalResult () {
      return 'Le ' + this.$moment(this.contest.regional_results_publication_date).format(dateFormat)
    },
    contestNationalResult () {
      return 'Le ' + this.$moment(this.contest.national_results_publication_date).format(dateFormat)
    },
    getRegionnalVoteLabel () {
      if (this.contest.simplified) {
        return 'Délibération du jury Eco CO2'
      }
      return 'Vote pour ta réalisation préférée'
    }
  }
}
</script>

<style lang="scss" scoped>
.description {
  text-align: center;

  .bold {
    font-weight: bold;
  }
}
.arrow {
  display: block;
  margin: auto;
}
.self-align-start {
  align-self: start;
}
.self-align-center {
  align-self: center;
}
</style>
