<template>
  <v-layout row wrap justify-center>
    <v-flex xs12 sm6>
      <h3 class="rules-title">Règlement du concours d'expression artistique</h3>
      <p class="banner-text">{{ bannerText }}</p>

      <div class="rules" v-html="rules">
      </div>
    </v-flex>
  </v-layout>
</template>

<script>
import marked from 'marked'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'ContestRules',
  computed: {
    ...mapState([
      'contest'
    ]),
    ...mapGetters([
      'hasContest'
    ]),
    rules () {
      if (this.hasContest) {
        return marked(this.contest.rules, { sanitize: true })
      } else {
        return ''
      }
    },
    bannerText () {
      if (this.hasContest) {
        return this.contest.slogan
      } else {
        return ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../styles/theme.scss';

  .banner-text {
    margin-top: $margin-m;
  }

</style>

<style lang="scss">
  @import '../styles/theme.scss';

  .rules-title, .banner-text {
    padding-left: $margin-l;
  }

  .rules {
    h1 {
      color: #BBCF31;
      font: normal normal bold 28px/37px Roboto;
      letter-spacing: 0px;
      margin-top: $margin-xxl;
      margin-bottom: $margin-m;
      padding-top: $margin-xs;
      padding-bottom: $margin-xs;
      padding-left: $margin-l;
    }

    :not(h2):not(h1):not(ul) {
      padding-left: $margin-l;
    }

    ul {
      margin-left: $margin-l;
    }
  }
</style>
