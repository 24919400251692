import 'core-js/stable'
import Vue from 'vue'
import router from './plugins/vue-router'
import axios from './plugins/axios'
import store from './plugins/vuex'
import './plugins/moment'
import './plugins/vuetify'
import App from './App.vue'
import './helpers/polyfill-toblob'

Vue.config.productionTip = false

store.axios = axios

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
