<template>
  <v-layout v-if="!isResultOpened" class="uploader-root" row wrap justify-center>
    <v-flex xs10 sm10 md7 v-if="!isContestOpened">
      <span class="closed-contest-header">
        L'envoi des réalisations est maintenant fermé !
      </span>
    </v-flex>
    <v-flex v-show="isContestOpened && !isUploading" xs10 sm10 md7>
      <v-layout row wrap justify-center>
        <v-flex xs12>
          <span>Participez</span>
        </v-flex>
        <v-flex xs12 md6>
            <v-select
                dark
                :items="submission_type"
                :item-text="item => item.label"
                :item-value="item => item.value"
                label="Catégorie de réalisation"
                v-model="selectedType"
            >
            </v-select>
            <DropZone v-if="!selectedFile && selectedType" v-model="selectedFile" />
            <UploadPreviewer
                :files="selectedFile"
                :thumbnails="thumbnails"
                v-if="thumbnails.length"
                @rotate="rotate"
                @setTime="setTime"
                @cancel="deleteFile" />
            <v-progress-circular v-if="isProgressVisible" indeterminate color="primary" />
        </v-flex>
        <v-flex class="align-vert" xs12 md6>
          <SchoolSelector v-model="selectedSchool" :simplified="simplified" :uploadAgain="uploadAgain" @setUploadAgainToFalse="setUploadAgainToFalse"/>
        </v-flex>
      </v-layout>
      <v-layout row wrap justify-end>
        <v-flex xs4 sm2 md1>
          <v-btn
            class="upload-submit"
            :disabled="submitDisabled"
            @click="submit()"
          >
            Envoyer
          </v-btn>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex v-show="isContestOpened && isUploading" xs7>
      <UploadProgress @resetform="deleteFile()" />
    </v-flex>
  </v-layout>
</template>

<script>
import DropZone from './DropZone'
import UploadPreviewer from './UploadPreviewer'
import SchoolSelector from './SchoolSelector'
import UploadProgress from './UploadProgress'

import { mapState, mapActions } from 'vuex'

import filehelper from '../../helpers/filehelper.js'

export default {
  components: {
    DropZone,
    UploadPreviewer,
    SchoolSelector,
    UploadProgress
  },

  props: {
    simplified: {
      type: Boolean,
      required: true
    }
  },

  data () {
    return {
      selectedFile: null,
      selectedSchool: null,
      thumbnails: [],
      uploadAgain: false,
      isProgressVisible: false,
      submission_type: [
        { label: 'Vidéo', value: 'video' },
        { label: 'Maquette', value: 'model' },
        { label: 'Dessin et/ou texte', value: 'drawing' }
      ],
      selectedType: null,
      rotation: []
    }
  },

  computed: {
    ...mapState({
      contest: state => state.contest,
      currentUploadProgressEvent: state => state.upload.currentUploadProgressEvent,
      currentUploadDone: state => state.upload.currentUploadDone
    }),
    isContestOpened () {
      if (this.contest) {
        const startDate = new Date(this.contest.upload_opening_date + 'T00:00')
        const endDate = new Date(this.contest.upload_closing_date + 'T00:00')
        // To include endDate
        endDate.setDate(endDate.getDate() + 1)
        const date = new Date()
        return startDate <= date && date < endDate
      } else {
        return true
      }
    },
    isResultOpened () {
      if (this.contest) {
        const startDate = this.$moment(this.contest.regional_results_publication_date)
        const date = this.$moment()
        return date.isAfter(startDate)
      } else {
        return true
      }
    },
    submitDisabled () {
      return !this.selectedFile || !this.selectedSchool
    },
    isUploading () {
      return this.currentUploadProgressEvent !== null && this.currentUploadProgressEvent !== undefined
    }
  },

  methods: {
    ...mapActions([
      'submitParticipation'
    ]),
    deleteFile () {
      this.selectedFile = null
      this.selectedSchool = null
      this.uploadAgain = true
    },
    submit () {
      const el = document.getElementById('participate')
      const rect = el.getBoundingClientRect()
      const scrollTop = window.scrollY || document.documentElement.scrollTop
      const top = rect.top + scrollTop - 200
      window.scrollTo(0, top)

      const data = {
        school: this.selectedSchool.school.id,
        level: this.selectedSchool.level.id,
        suffix: this.selectedSchool.klass,
        files: this.selectedFile,
        filetype: this.selectedType,
        artist: this.selectedSchool.artist,
        thumbnails: this.thumbnails,
        canBeUsed: this.selectedSchool.canBeUsed,
        isLegalRepresentative: this.selectedSchool.isLegalRepresentative
      }
      this.isProgressVisible = true
      this.submitParticipation(data)
        .then(() => {
          this.isProgressVisible = false
          this.deleteFile()
        })
        .catch(() => {
          this.isProgressVisible = false
          this.deleteFile()
        })
    },
    setUploadAgainToFalse () {
      this.uploadAgain = false
    },
    rotate (index) {
      switch (this.rotation[index]) {
        case 1:
          this.rotation[index] = 8
          break
        case 8:
          this.rotation[index] = 3
          break
        case 3:
          this.rotation[index] = 6
          break
        case 6:
          this.rotation[index] = 1
          break
      }
      this.isProgressVisible = true
      filehelper.generateThumbnailBlob(this.selectedFile[index], this.rotation[index]).then(blob => {
        this.isProgressVisible = false
        this.$set(this.thumbnails, index, {
          dataBlob: blob,
          dataUrl: URL.createObjectURL(blob),
          name: this.selectedFile[index].name,
          rotation: this.rotation[index]
        })
      })
    },
    setTime ({ index, time }) {
      this.isProgressVisible = true
      filehelper.generateThumbnailBlob(this.selectedFile[index], 0, time).then(blob => {
        this.isProgressVisible = false
        this.$set(this.thumbnails, index, {
          dataBlob: blob,
          dataUrl: URL.createObjectURL(blob),
          name: this.selectedFile[index].name,
          rotation: this.rotation[index]
        })
      })
    }
  },
  watch: {
    selectedFile () {
      this.thumbnails = []
      this.rotation = []
      if (this.selectedFile) {
        this.selectedFile.forEach((file) => {
          this.rotation.push(1)
          this.isProgressVisible = true
          filehelper.generateThumbnailBlob(file, 1).then(blob => {
            this.isProgressVisible = false
            this.thumbnails.push({
              dataBlob: blob,
              dataUrl: (window.URL || window.webkitURL).createObjectURL(blob),
              name: file.name,
              rotation: 1
            })
          })
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/theme.scss';

.uploader-root {
  background: #007789;
  width: 100%;
  color: white;
  font-size: 20px;
  font-weight: bold;
  padding-top: $margin-xl;
  padding-bottom: $margin-xl;

  .align-vert {
    display: flex;
    flex: 1;
    text-align: center;
    align-items: center;
    justify-content:center;

    padding: $margin-xl;

    @media screen and (max-width: $screen_md) {
      padding: 0;
    }
    @media screen and (max-width: $screen_xs) {
      flex: auto;
      padding: $margin-xl $margin-xl 0;
    }
  }

  .upload-submit {
    @media screen and (min-width: $screen_xs) and (max-width: $screen_sm) {
      margin-right: 0;
      margin-left: auto;
      display: flex;
    }
  }
  .closed-contest-header {
    display:block;
    color: white;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    font-family: 'Verdana, Geneva, sans-serif';
    margin-top: 50px;
    margin-bottom: 50px;
  }
}
</style>
