<template>
  <div>
    <v-toolbar
      class="navbar-container"
      :class="navbarClasses"
      color="white"
      :flat="flatToolbar"
      fixed
      app
      :height="toolbarHeight"
      v-scroll="onScroll"
    >
      <v-btn
        @click.stop="drawer = !drawer"
        icon
        class="hidden-sm-and-up left-burger"
      >
        <v-icon>menu</v-icon>
      </v-btn>
      <v-layout column align-center class="pt-6" hidden-xs-only>
        <router-link class="tab" :to="{ name: 'main' }">
          <img
            :src="require('../../assets/watty_logo.png')"
            style="height: 58px"
          />
        </router-link>
        <hr
          style="width: 233px; border: 1px solid #bbcf32"
          v-if="flatToolbar"
        />
        <a class="tab" href="https://www.ecoco2.com/" v-if="flatToolbar">
          <img
            :src="require('../../assets/eco_logo.png')"
            style="height: 59px"
          />
        </a>
      </v-layout>
      <v-layout column align-center class="pt-6" hidden-sm-and-up>
        <router-link class="tab" :to="{ name: 'main' }">
          <img
            :src="require('../../assets/watty_logo.png')"
            style="height: 58px"
          />
        </router-link>
      </v-layout>
      <div class="navbar-tabs hidden-xs-only">
        <span v-for="item in items" :key="item.title">
          <router-link v-if="item.to && item.to.name" class="tab" :to="item.to">
            {{ item.title }}
          </router-link>

          <a
            v-else-if="item.to && item.to.hash"
            class="tab"
            :href="item.to.hash"
            :class="{
              'router-link-exact-active': item.to.hash === $route.hash,
            }"
          >
            {{ item.title }}
          </a>

          <a v-else class="tab" :href="item.href" target="_blank">
            {{ item.title }}
          </a>
        </span>
      </div>
    </v-toolbar>
    <v-navigation-drawer v-model="drawer" app fixed floating class="menu-nav">
      <v-list>
        <v-list-tile
          v-for="item in items"
          :key="item.title"
          @click="open_link(item)"
        >
          <v-list-tile-content>
            <v-list-tile-title>{{ item.title }}</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data () {
    return {
      scrollPosition: 0,
      isMenuOpen: false,
      drawer: false
    }
  },

  computed: {
    ...mapState({ contest: (state) => state.contest }),
    flatToolbar () {
      return this.scrollPosition === 0
    },
    navbarClasses () {
      return {
        open: this.isMenuOpen
      }
    },
    toolbarHeight () {
      return this.scrollPosition === 0 && !this.drawer ? 144 : 70
    },
    currentRoute () {
      return this.$route.name
    },
    isContestOpened () {
      if (this.contest) {
        const startDate = new Date(this.contest.upload_opening_date + 'T00:00')
        const endDate = new Date(this.contest.upload_closing_date + 'T00:00')
        // To include endDate
        endDate.setDate(endDate.getDate() + 1)
        const date = new Date()
        return startDate <= date && date < endDate
      } else {
        return true
      }
    },
    items () {
      return [
        {
          title: 'Concours',
          to:
            this.currentRoute === 'main'
              ? { hash: '#contest' }
              : { name: 'main', hash: '#contest' }
        },
        {
          title: 'Participer',
          to:
            this.currentRoute === 'main'
              ? { hash: '#participate' }
              : { name: 'main', hash: '#participate' }
        },
        { title: 'Règles', to: { name: 'rules' } },
        { title: 'Aide', to: { name: 'help' } },
        { title: 'Le Programme Watty', href: 'http://www.watty.fr' }
      ]
    }
  },

  watch: {
    isMenuOpen (value) {
      document.querySelector('html').style.overflow = value ? 'hidden' : 'auto'
    },
    $route () {
      this.isMenuOpen = false
    }
  },

  methods: {
    onScroll () {
      this.scrollPosition =
        window.pageYOffset || document.documentElement.scrollTop
    },
    onMenuToggle () {
      this.isMenuOpen = !this.isMenuOpen
    },
    open_link (item) {
      if (item.to) {
        this.$router.push(item.to)
      } else {
        window.open(item.href, '_blank')
      }
    }
  }
}
</script>

<style lang="scss">
@import "../../styles/theme.scss";

.navbar-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  z-index: 35;

  .v-toolbar__content {
    padding: 0;
  }

  &.open .navbar-tabs {
    top: 48px;

    @media (max-width: 477px) {
      top: 56px;
    }
  }

  .navbar-inner-container {
    display: flex;
    justify-content: space-between;
    background: white;
    padding: 0 10px;

    @media (max-width: 499px) {
      width: 100%;
      height: 100%;
      z-index: 50;
    }
  }
  .navbar-tabs {
    flex: 1 0 auto;
    flex-direction: row;
    padding: 0 0 0 100px;
    font-size: 0;
    transition: ease-out 150ms;

    @media (max-width: 959px) {
      height: 48px;
      padding: 0 0 0 70px;
    }
    @media (max-width: 859px) {
      padding: 0 0 0 30px;
    }
    @media (max-width: 659px) {
      padding: 0 0 0 10px;
    }

    @media (max-width: 499px) {
      display: block;
      height: auto;
      padding: 0;
      top: -140px;
      left: 0;
      right: 0;
      position: absolute;
      z-index: 40;
      border-top: 1px solid #ddd;
    }

    .tab {
      position: relative;
      display: inline-flex;
      height: 100%;
      padding: 6px 12px;
      font-size: 14px;
      font-weight: 500;
      text-decoration: none;
      align-items: center;
      background: white;
      transition: ease-out 250ms;

      @media (min-width: 500px) and (max-width: 659px) {
        font-size: 12px;
        padding: 6px 8px;
      }

      @media (max-width: 499px) {
        display: block;
        width: 100%;
        padding: 8px 12px;
        font-weight: 700;
        align-items: unset;
        text-align: center;
      }

      &:hover {
        color: $dark-primary-color;
      }
      &:active {
        background: #f7f7f7;
      }

      @media (min-width: 500px) {
        &.router-link-exact-active::after {
          content: "";
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 3px;
          background: $primary;
        }
      }
    }
  }
}

.v-tabs__item {
  color: $primary !important;
  text-transform: none;

  &:hover {
    color: $dark-primary-color !important;
  }
}
.menu-nav.v-list__tile__title {
  color: $primary;
}
</style>
