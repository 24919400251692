<template>
  <div class="parent">
    <v-layout v-if="!currentUploadDone"
      row wrap justify-center>
      <v-flex sm4 sm1>
        <v-progress-circular
          v-if="progress < 1"
          indeterminate
          class="progress"
          :size="100"
          :width="15"
          :value="progress"
          color="primary">
        </v-progress-circular>

        <v-progress-circular
          v-else
          class="progress"
          :rotate="-90"
          :size="100"
          :width="15"
          :value="progress"
          color="primary">
          {{ progress }} %
        </v-progress-circular>
        <h3>
          Envoi en cours
        </h3>
      </v-flex>
    </v-layout>
    <v-layout v-if="currentUploadDone"
              justify-center
              row
              wrap>
      <v-flex v-if="!!currentUploadError" xs12 sm6 class="center">
        <p>{{currentUploadError}}</p>
        <v-btn color="primary" @click="resetForm(false)">Réessayer</v-btn>
      </v-flex>
      <v-flex v-else xs12 sm6 class="center">
        <p><v-icon color="success">mdi-check</v-icon> L'envoi est terminé et en cours de modération</p>
        <v-btn color="primary" @click="resetForm(true)">Envoyer une autre participation</v-btn>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  computed: {
    ...mapState({
      currentUploadProgressEvent: state => state.upload.currentUploadProgressEvent,
      currentUploadDone: state => state.upload.currentUploadDone,
      currentUploadError: state => state.upload.currentUploadError,
      writeCode: state => state.writeCode,
      readCode: state => state.readCode
    }),

    progress () {
      const pe = this.currentUploadProgressEvent
      if (pe && pe.lengthComputable && pe.total > 0) {
        return Math.round(100 * (pe.loaded / pe.total))
      } else {
        return 0
      }
    },

    returnLink () {
      return location.protocol + '//' + location.host + this.$router.currentRoute.path + '?writeCode=' + this.writeCode + '&readCode=' + this.readCode
    },

    hasClipboard () {
      return navigator.clipboard
    }
  },

  methods: {
    ...mapMutations([
      'resetUpload'
    ]),

    resetForm (resetForm) {
      this.resetUpload()
      if (resetForm) {
        this.$emit('resetform', resetForm)
      }
    },

    copyLinkToClipboard () {
      navigator.clipboard.writeText(this.returnLink)
    }
  }
}
</script>

<style lang="scss" scoped>
  .parent {
    width: 100%;

    .progress {
      margin-top: 10px;
      margin-left: auto;
      margin-right: auto;
      display: block;
      font-size: 18px !important;
    }

    h3 {
      text-align: center;
      margin-top: 20px;
    }

    .center { text-align: center; }

    .copy-link-caption {
      font-size: 14px;
    }
  }
</style>
