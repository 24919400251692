import { render, staticRenderFns } from "./ValidatePage.vue?vue&type=template&id=7a54b3fe&scoped=true&"
import script from "./ValidatePage.vue?vue&type=script&lang=js&"
export * from "./ValidatePage.vue?vue&type=script&lang=js&"
import style0 from "./ValidatePage.vue?vue&type=style&index=0&id=7a54b3fe&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a54b3fe",
  null
  
)

export default component.exports