<template>
  <v-layout row wrap justify-center>
    <v-flex xs12 v-if="!loaded">
      <v-layout row wrap justify-center>
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-layout>
    </v-flex>
    <v-flex xs12>
      <v-container grid-list-xl>
        <v-layout row wrap justify-center>
          <v-flex
            xs12
            sm12
            md3
            v-for="(submission, submission_title) in getWinnerSubmissions"
            :key="submission_title"
          >
            <GreenTitle :title="submission_title" full class="mb-5 mt-3" />
            <ParticipationCard
              @onimageclicked="openFullscreen(submission)"
              :rank="submission.rank + 1"
              :participation="submission"
              :likeable="false"
              class="hidden-md-and-up"
            />
          </v-flex>
        </v-layout>
        <v-layout row wrap justify-center hidden-sm-and-down>
          <v-flex
            md3
            v-for="(submission, submission_title) in getWinnerSubmissions"
            :key="submission_title"
          >
            <ParticipationCard
              @onimageclicked="openFullscreen(submission)"
              :rank="submission.rank + 1"
              :participation="submission"
              :likeable="false"
            />
          </v-flex>
        </v-layout>
        <v-layout row wrap justify-center> </v-layout>
      </v-container>
    </v-flex>
  </v-layout>
</template>

<script>
import ParticipationCard from './ParticipationCard'
import { mapState, mapActions } from 'vuex'
import GreenTitle from '../GreenTitle'

export default {
  components: {
    ParticipationCard,
    GreenTitle
  },

  data () {
    return {
      loaded: false,
      getWinnerSubmissions: null,
      filetype_label: {
        video: 'Vidéo',
        model: 'Maquette',
        drawing: 'Dessin et/ou texte'
      }
    }
  },

  computed: {
    ...mapState({
      contest: (state) => state.contest,
      rank: (state) => state.rankNational,
      favorite: (state) => state.favorite
    }),
    additionalWinner () {
      const winner = this.favorite
      return winner
    }
  },

  methods: {
    ...mapActions(['fetchRankNational', 'fetchFavorite']),

    openFullscreen (submission) {
      if (!submission.from) {
        submission.from = 'national'
      }
      this.$emit('onimageclicked', submission)
    }
  },

  watch: {
    rank (ranks) {
      if (ranks) {
        const winners = Object.fromEntries(
          ranks.winners.map((winner) => {
            return [
              'Gagnant<br />National<br />' +
                this.filetype_label[winner.filetype],
              winner
            ]
          })
        )

        // if (this.additionalWinner && this.additionalWinner.filetype) {
        //   winners[
        //     'Coup de coeur<br />National<br />' +
        //       this.filetype_label[this.additionalWinner.filetype]
        //   ] = this.additionalWinner
        // }

        this.loaded = true
        this.getWinnerSubmissions = Object.assign({}, winners)
      }
    }
  },

  mounted () {
    this.fetchRankNational()
    this.fetchFavorite()
  }
}
</script>
