<template>
<div>
  <v-layout row wrap justify-center>
    <v-flex xs3>
      <v-text-field label="Clé d'authentification"
                    single-line
                    solo
                    v-model="token"/>
    </v-flex>
  </v-layout>
  <v-layout row wrap justify-center v-if="participationToValidate">
    <v-flex xs4>
      <v-img class="thumbnail"
              :src="participationToValidate.thumbnail"/>
    </v-flex>
    <v-flex xs4>
      <v-img  v-if="isImage"
              class="file"
              :src="participationToValidate.file" />

      <video v-if="isVideo"
             class="file"
             :src="participationToValidate.file" />
      <PDFViewer v-if="isPdf"
              class="file pdf"
             :file-url="participationToValidate.file"/>
    </v-flex>
  </v-layout>
  <v-layout row wrap justify-center v-else>
    <p>
      Rien à valider
    </p>
  </v-layout>
  <v-layout row wrap justify-center>
    <v-flex xs1>
      <v-btn color="success" @click="validate(true)">Valider</v-btn>
    </v-flex>
    <v-flex xs1>
      <v-btn color="error" @click="validate(false)">Rejeter</v-btn>
    </v-flex>
    <v-flex xs1>
      <v-btn flat @click="skip()">Passer</v-btn>
    </v-flex>
  </v-layout>
</div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import filehelper from '../helpers/filehelper'
import PDFViewer from './Views/PDFViewer'

export default {
  name: 'ValidatePage',
  components: {
    PDFViewer
  },

  data: function () {
    return {
      token: null
    }
  },

  computed: {
    ...mapState({
      participationToValidate: state => state.validation.participationToValidate
    }),
    ...mapGetters([
      'getValidationToken'
    ]),

    isVideo () {
      if (this.participationToValidate) {
        return filehelper.isVideo(this.participationToValidate.file)
      }
      return false
    },

    isImage () {
      if (this.participationToValidate) {
        return filehelper.isImage(this.participationToValidate.file)
      }
      return false
    },

    isPdf () {
      if (this.participationToValidate) {
        return filehelper.isPdf(this.participationToValidate.file)
      }
      return false
    }
  },

  watch: {
    token () {
      if (this.token && this.token.length === 40) {
        this.setValidationToken(this.token)
      }
    },

    getValidationToken () {
      if (this.getValidationToken) {
        this.fetchParticipationToValidate()
      }
    }
  },

  methods: {
    ...mapActions([
      'fetchParticipationToValidate',
      'setValidation'
    ]),
    ...mapMutations([
      'setValidationToken'
    ]),

    validate (validated) {
      this.setValidation({ id: this.participationToValidate.id, validated })
    },

    skip () {
      this.fetchParticipationToValidate()
    }
  },

  mounted () {
    if (this.validationToken) {
      this.token = this.validationToken
    }
  }
}
</script>

<style lang="scss" scoped>
  .thumbnail, .file  {
    max-width: 400px;
    max-height: 400px;
  }
  .pdf {
    width: 400px;
    height: 400px;
  }

</style>
