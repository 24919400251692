import Vue from 'vue'
import axios from 'axios'

const production = process.env.NODE_ENV === 'production'
const baseUrl = production ? window.location.origin + '/api/v1/' : 'http://localhost:3333/api/v1/'
const instance = axios.create({
  baseURL: baseUrl,
  timeout: 5000
})

const plugin = {
  install (Vue) {
    Vue.prototype.$axios = instance
  }
}

Vue.use(plugin)

export default instance
