<template>
  <iframe id="pdfviewer"
          frameborder="0"
          scrolling="yes"
          seamless="seamless"
          :src="iFrameUrl"></iframe>
</template>

<script>
export default {
  props: {
    fileUrl: {
      type: String,
      required: true
    }
  },

  computed: {
    iFrameUrl () {
      const viewerUrl = 'pdfjs/web/viewer.html?file=' + encodeURIComponent(this.fileUrl) + '#view=fit'
      return viewerUrl
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
