const CanvasExifOrientation = require('canvas-exif-orientation')

const maxThumbnailWidth = 400
const maxThumbnailHeight = 400

export default {
  videoExtensions: ['.mp4', '.m4v'],
  imageExtensions: ['.jpg', '.jpeg', '.png', '.gif'],
  pdfExtensions: ['.pdf'],

  videoMimeType: ['video/mp4'],
  imageMimeType: ['image/png', 'image/jpeg', 'image/gif'],
  pdfMimeType: ['application/pdf'],

  /**
   * Returns the lowercase file extension from a filename.
   * Only works if there is an extension (a . followed by some letters)
   *
   * @param {String|Object} file The file name as a String, or the File object
   */
  getFileExtension (file) {
    let filename = ''
    if (typeof file === 'string') {
      filename = file
    } else {
      filename = file.name
    }
    return filename.substr(filename.lastIndexOf('.')).toLowerCase()
  },

  isVideo (file) {
    const extension = this.getFileExtension(file)
    const isExtensionValid = this.videoExtensions.indexOf(extension) > -1
    const isMimeTypeValid = typeof file === 'string' || this.videoMimeType.indexOf(file.type) > -1
    return isExtensionValid && isMimeTypeValid
  },

  isImage (file) {
    const extension = this.getFileExtension(file)
    const isExtensionValid = this.imageExtensions.indexOf(extension) > -1
    const isMimeTypeValid = typeof file === 'string' || this.imageMimeType.indexOf(file.type) > -1
    return isExtensionValid && isMimeTypeValid
  },

  isPdf (file) {
    const extension = this.getFileExtension(file)
    const isExtensionValid = this.pdfExtensions.indexOf(extension) > -1
    const isMimeTypeValid = typeof file === 'string' || this.pdfMimeType.indexOf(file.type) > -1
    return isExtensionValid && isMimeTypeValid
  },

  /**
   * Checks if the given file has a valid length.
   * Videos must weight less than 500Mib
   * Images must weight less than 55Mib
   * Pdfs must weight less than 20 Mib
   * @param {Object} file
   */
  isSizeValid (file) {
    if (this.isVideo(file)) {
      return file.size <= 500 * 1024 * 1024
    }
    if (this.isImage(file)) {
      return file.size <= 5 * 1024 * 1024
    }
    if (this.isPdf(file)) {
      return file.size <= 20 * 1024 * 1024
    }
  },

  generateImageThumbnail (file, rotation) {
    return new Promise(resolve => {
      const reader = new FileReader()

      reader.onloadend = function (event) {
        const img = new Image()
        let width; let height = 0
        img.onload = function () {
          if (img.width > img.height) {
            width = Math.min(maxThumbnailWidth, img.width)
            height = img.height * (width / img.width)
          } else {
            height = Math.min(maxThumbnailHeight, img.height)
            width = img.width * (height / img.height)
          }
          // ctx.drawImage(img, 0, 0, canvas.width, canvas.height)
          resolve(CanvasExifOrientation.drawImage(img, rotation, 0, 0, width, height))
        }
        img.src = event.target.result
      }
      reader.readAsDataURL(file)
    })
  },

  generateVideoThumbnail (file, timestamp) {
    return new Promise(resolve => {
      const video = document.createElement('video')
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      const reader = new FileReader()

      reader.onloadend = function (event) {
        video.onloadedmetadata = function () {
          // video.currentTime = timestamp ? timestamp : Math.round(video.duration / 3)
          video.currentTime = Math.round(video.duration / 20)
          const videoWidth = video.videoWidth
          const videoHeight = video.videoHeight

          if (videoWidth > videoHeight) {
            canvas.width = Math.min(maxThumbnailWidth, videoWidth)
            canvas.height = videoHeight * (canvas.width / videoWidth)
          } else {
            canvas.height = Math.min(maxThumbnailHeight, videoHeight)
            canvas.width = videoWidth * (canvas.height / videoHeight)
          }
        }
        video.ontimeupdate = function () {
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height)
          resolve(canvas)
          window.URL.revokeObjectURL(videoURL)
        }
        const videoURL = window.URL.createObjectURL(file)
        video.src = videoURL
        video.load()
      }

      reader.readAsDataURL(file)
    })
  },

  generatePdfThumbnail (file) {
    return new Promise(resolve => {
      import('pdfjs-dist/webpack').then(pdfjs => {
        const pdfLoadingTask = pdfjs.getDocument(URL.createObjectURL(file))
        pdfLoadingTask.promise
          .then(pdf => {
            return pdf.getPage(1)
          })
          .then(page => {
            let viewport = page.getViewport({ scale: 1 })
            const canvas = document.createElement('canvas')
            const pdfWidth = viewport.width
            const pdfHeight = viewport.height
            // Prepare canvas using PDF page dimensions
            if (pdfWidth > pdfHeight) {
              canvas.width = Math.min(maxThumbnailWidth, pdfWidth)
              canvas.height = pdfHeight * (canvas.width / pdfWidth)
            } else {
              canvas.height = Math.min(maxThumbnailHeight, pdfHeight)
              canvas.width = pdfWidth * (canvas.height / pdfHeight)
            }
            const scale = Math.min(canvas.width / viewport.width, canvas.height / viewport.height)
            viewport = page.getViewport(scale)

            // Render PDF page into canvas context
            const canvasContext = canvas.getContext('2d')

            page.render({ canvasContext, viewport }).promise
              .then(() => {
                resolve(canvas)
              })
          })
      })
    })
  },

  async generateThumbnailBlob (file, rotation, timestamp = false) {
    let canvas = null
    if (this.isImage(file)) {
      canvas = await this.generateImageThumbnail(file, rotation)
    } else if (this.isVideo(file)) {
      canvas = await this.generateVideoThumbnail(file, timestamp)
    } else if (this.isPdf(file)) {
      canvas = await this.generatePdfThumbnail(file)
    }

    return new Promise(resolve => {
      canvas.toBlob(blob => resolve(blob), 'image/jpeg')
    })
  }

}
