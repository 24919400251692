<template>
  <div
    :class="{ 'full-height': !this.fullscreen || isPdf }"
    @mouseover="onHover = true" @mouseleave="onHover = false"
  >
  <v-card class="parent-card" height="100%">
    <v-card-title dark v-if="title">
      <span class="title font-weight-bold">{{ title }}</span>
    </v-card-title>
      <div class="media">
        <div class="v-carousel__controls" v-if="onHover && participation.files.length > 1">
          <div class="v-item-group theme--dark">
            <button
              v-for="(file, nb) in participation.files"
              :key="nb"
              type="button"
              class="v-btn v-btn--active v-btn--icon v-btn--small theme--dark v-carousel__controls__item"
              @click="currentFile = nb"
            >
              <div class="v-btn__content">
                <i aria-hidden="true" class="v-icon mdi mdi-circle theme--dark" style="font-size: 18px;"></i>
              </div>
            </button>
          </div>
        </div>
            <v-img  v-if="!displayOriginalFile || isImage"
                  width="100%" contain
                  max-height="85vh"
                  height="100%"
                  @click="onImageClicked"
                  :src="fileSrc"></v-img>
            <vue-player v-else-if="isVideo"
                :src="fileSrc"
                :poster="fileThumbnail"
                v-model="playing"
            >
            </vue-player>
            <PDFViewer  v-else-if="isPdf"
                        width="100%"
                        height="100%"
                        min-height="500px"
                        class="viewer"
                        :fileUrl="fileSrc" />
      </div>

      <v-card-text class="footer">
        <v-layout>
          <v-flex class="xs8">
            <span v-if="fullscreen">
              <span v-if="participation.artist">
                {{ participation.artist }} en {{ participation.level }}
                Classe de ({{ participation.suffix }})
              </span>
              <span v-else>
                Classe de {{ participation.suffix }} en {{ participation.level }}
              </span>
                Ecole {{ participation.school }}
            </span>
            <span v-else>
              <span v-if="participation.artist">
                <span>{{ participation.artist }} en {{ participation.level }}</span><br />
                <span>Classe de {{ participation.suffix }}</span><br />
              </span>
              <span v-else>
                <span>Classe de {{ participation.suffix }} en {{ participation.level }}</span><br>
              </span>
              <span class="grey--text">Ecole: {{ participation.school }}</span>
            </span>
          </v-flex>

          <v-flex class="xs4 footer-actions">
            <v-btn
              v-if="deletable"
              @click="openDeleteDialog()"
              slot="activator" flat icon dark>
              <v-icon color="#000">mdi-delete</v-icon>
            </v-btn>

            <template v-if="likeable">
              <v-tooltip top :open-delay="500">
                <v-btn @click="toggleLike()" slot="activator" flat icon dark>
                  <v-icon :color="likeIconColor">{{ likeIcon }}</v-icon>
                </v-btn>
                <span>{{ likeIconTooltipContent }}</span>
              </v-tooltip>
            </template>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>

    <!-- Delete Confirmation modal -->
    <v-dialog v-if="deletable" v-model="dialog" lazy max-width="500">
      <v-card class="container-dialog">
        <v-card-title class="headline">Supprimer la participation {{ pronoun }}{{ participation.artist }}</v-card-title>
        <v-card-text>
          Vous allez supprimer la participation de {{ participation.artist }}. Cette action est irreversible. Une fois supprimée, vous
          pourrez ajouter une nouvelle participation pour {{ participation.artist }}.
        </v-card-text>
        <v-card-actions>
          <v-btn class="btn-action" color="darken-1" flat="flat" @click="dialog = false">
            Annuler
          </v-btn>
          <v-btn class="error" color="darken-1" flat="flat" @click="deleteElement()">
            Supprimer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import PDFViewer from './PDFViewer'
import filehelper from '../../helpers/filehelper.js'
import vuePlayer from '@algoz098/vue-player'

export default {
  components: {
    PDFViewer,
    vuePlayer
  },

  props: {
    participation: {
      type: Object,
      required: true
    },
    deletable: {
      type: Boolean,
      default: false
    },
    likeable: {
      type: Boolean,
      default: false
    },
    liked: {
      type: Boolean,
      default: false
    },
    displayOriginalFile: {
      type: Boolean,
      default: false
    },
    rank: {
      type: Number,
      default: null
    },
    fullscreen: {
      type: Boolean,
      default: false
    },
    title: {
      type: String
    }
  },

  data () {
    return {
      dialog: false,
      pronoun: 'de ',
      currentFile: 0,
      onHover: false,
      playing: false
    }
  },

  computed: {
    isVideo () {
      if (this.participation) {
        return filehelper.isVideo(this.participation.files[this.currentFile].file)
      } else {
        return false
      }
    },

    isImage () {
      if (this.participation) {
        return filehelper.isImage(this.participation.files[this.currentFile].file)
      } else {
        return false
      }
    },

    isPdf () {
      if (this.participation) {
        return filehelper.isPdf(this.participation.files[this.currentFile].file)
      } else {
        return false
      }
    },

    fileSrc () {
      if (this.participation) {
        if (this.displayOriginalFile) {
          return this.participation.files[this.currentFile].file
        } else {
          return this.participation.files[this.currentFile].thumbnail
        }
      } else {
        return ''
      }
    },

    fileThumbnail () {
      if (this.participation) {
        return this.participation.files[this.currentFile].thumbnail
      } else {
        return ''
      }
    },

    likeIcon () {
      return this.liked ? 'mdi-heart' : 'mdi-heart-outline'
    },
    likeIconColor () {
      return this.liked ? '#D42438' : '#777'
    },
    likeIconTooltipContent () {
      if (!this.liked) {
        return 'Voter pour cette participation'
      }

      return 'Supprimer le vote'
    }
  },

  methods: {
    ...mapActions([
      'deleteParticipation',
      'likeParticipation',
      'unlikeParticipation'
    ]),

    openDeleteDialog () {
      this.dialog = true
      this.changePronoun()
    },

    toggleLike () {
      if (!this.liked) {
        this.likeParticipation(this.participation.id)
      } else {
        this.unlikeParticipation(this.participation.id)
      }
    },

    closeDialog () {
      this.dialog = false
    },

    deleteElement () {
      this.closeDialog()
      this.deleteParticipation(this.participation.id)
    },

    changePronoun () {
      const artist = this.participation.artist.toLowerCase()
      if (artist.charAt(0) === 'a' ||
         artist.charAt(0) === 'e' ||
         artist.charAt(0) === 'i' ||
         artist.charAt(0) === 'O' ||
         artist.charAt(0) === 'u' ||
         artist.charAt(0) === 'y' ||
         artist.charAt(0) === 'h') {
        this.pronoun = "d'"
      } else {
        this.pronoun = 'de '
      }
      return this.pronoun
    },

    onImageClicked () {
      this.$emit('onimageclicked')
    },

    getSupRank (rank) {
      if (rank > 1) {
        return 'e'
      }
      return 'er'
    },

    getRankColor (rank) {
      const color = [
        '#BBCF32',
        '#8ACBC3',
        '#EC730A',
        '#027789',
        '#6C2813',
        '#778822'
      ]
      return color[(rank - 1) % 6]
    },

    previous () {
      this.currentFile -= 1
    },

    next () {
      this.currentFile += 1
    }
  },
  watch: {
    participation (value) {
      this.currentFile = 0
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../styles/theme.scss";

  .btn-action { margin-left: auto!important; }

  .card-delete {
    background-color: $primary !important;
    color: $primary-text-color !important;
  }

  .parent-card {
    display: flex;
    flex-direction: column;
    height: 100%;
    border: 1px solid #707070;
    border-radius: 7px;
    padding: 10px;
    .header {
      color: #fff;
      text-align: center;
      padding-top: 10px;
      font-size: 30px;
      padding-bottom: 10px;
      font-weight: bold;
    }
  }

  .media {
    flex: 1 0 auto;
    cursor: pointer;
  }

  .footer {
    flex: 0 0 auto;

    .footer-actions {
      text-align: right;
    }
  }

  .title {
    color: $primary;
  }
  .full-height {
    height: 100%;
  }
</style>
