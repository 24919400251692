<template>
  <v-layout row wrap justify-center class="privacy">
    <v-flex xs12 sm6>
      <h1>Politique de confidentialité</h1>
      <p>
        La présente Politique de confidentialité s’adresse à tout internaute en sa qualité d’Utilisateur des différents sites du portail du Groupe Eco CO2 (ci-après le « Site »). Le Site est édité par la société Eco CO2, SAS, immatriculée au RCS de Nanterre sous le numéro 511 644 601, ayant pour numéro de TVA intra-communautaire FR 92 511 644 601, dont le siège social est situé 3 bis rue du Docteur Foucault, 92000 Nanterre (ci-après « Eco CO2 »).<br/><br/>

        Le respect de la vie privée et des données à caractère personnel des Utilisateurs est une priorité pour Eco CO2. La présente Politique de confidentialité a pour objet de présenter à l’Utilisateur les traitements de données à caractère personnel mis en œuvre sur le Site.<br/><br/>

        Eco CO2 collecte uniquement les données strictement nécessaires à leur finalité, s’emploie à traiter les données de manière transparente, confidentielle et sécurisée et s’engage à respecter le droit applicable en matière de protection des données à caractère personnel.
      </p>

      <h2>ARTICLE 1. RESPONSABLE DE TRAITEMENT</h2>

      <p>
        Les données à caractère personnel sont collectées et traitées par la société Eco CO2, SAS, immatriculée au RCS de Nanterre sous le numéro 511 644 601, ayant pour numéro de TVA intra-communautaire FR 92 511 644 601, dont le siège social est situé 3 bis rue du Docteur Foucault, 92000 Nanterre. Eco CO2 est le Responsable de traitement des données collectées sur le Site.<br/><br/>

        Pour toute question relative à la gestion et à l’utilisation faite des données à caractère personnel et pour exercer ses droits, l’Utilisateur est invité à joindre le délégué à la protection des données personnelles par mail à ccil@ecoco2.com ou par courriel à Eco CO2 – Délégué à la protection des données, 3 bis rue du Docteur Foucault, 92000 Nanterre.
      </p>

      <h2>ARTICLE 2. FINALITES DU TRAITEMENT</h2>

      <p>
        Les différentes données sont susceptibles d’être collectées par Eco CO2 pour des finalités explicites, légitimes et déterminées afin d’assurer&nbsp;:
        <ul>
          <li>Le bon fonctionnement et l’amélioration permanente du Site, de ses services et de ses fonctionnalités ;</li>
          <li>La gestion de l’inscription de l’Utilisateur à l’ensemble des prestations et outils proposées par Eco CO2 et disponibles sur le Site ;</li>
          <li>La gestion de votre abonnement à la newsletter d’Eco CO2 ;</li>
          <li>La gestion de demandes de téléchargement des documents élaborés par Eco CO2 ;</li>
          <li>La gestion des demandes de l’Utilisateur via les différents formulaires disponibles sur le Site ;</li>
          <li>La gestion des demandes de droits cités à l’article 7. Vos droits ;</li>
          <li>L’élaboration de statistiques commerciales, analyses de fréquentation et mesures d’audience.</li>
        </ul>
        <br/>
        En tout état de cause, et pour chaque finalité définie, Eco CO2 met tous les moyens en sa possession pour assurer la sécurité et la confidentialité des données à caractère personnel qui lui sont confiées, dans le respect des lois et règlement en vigueur.
      </p>

      <h2>ARTICLE 3. TRAITEMENT DES DONNEES PERSONNELLES</h2>

      <p>
        Lorsque l’Utilisateur navigue sur le Site et/ou souhaite bénéficier des services disponibles sur le Site, Eco CO2 peut collecter certaines données personnelles. Ces données sont traitées conformément aux finalités prévues lors de la collecte.
        <br/><br/>
        Les données susceptibles d’être collectées et traitées par Eco CO2 pour la réalisation des finalités décrites à l’article 2 de la présente Politique de confidentialité concernent :
        <ul>
          <li>Des données d’identification (nom, prénom, courriel, numéro de téléphone, entreprise, fonction, éventuellement copie de la carte d’identité aux fins de preuve de l’exercice d’un droit) ;</li>
          <li>Des données relatives à la gestion et à la sécurisation de votre compte (identifiants et mots de passe) ;</li>
          <li>Des données relatives à la relation commerciale : demandes d’information, historique des échanges ;</li>
          <li>Des données de connexion et cookies ;</li>
        </ul>
        <br/>
        Eco CO2 s’engage à mettre en œuvre les mesures techniques et organisationnelles afin d’assurer la sécurité des traitements de données à caractère personnel, la confidentialité et l’intégrité des données.
        <br/><br/>
        Les données concernant l’Utilisateur sont collectées lors de sa connexion, son inscription, ses commandes, et ses échanges sur le Site sur les divers formulaires. Eco CO2 recueille votre consentement à chaque utilisation directement sur le site ; pour exercer son droit de retrait de son consentement, l’Utilisateur peut envoyer un email à ccil@ecoco2.com.
      </p>

      <h2>ARTICLE 4. DESTINATAIRES DES DONNEES</h2>

      <p>
        Seules les personnes habilitées et déterminées, mentionnées ci-après, pourront avoir accès aux données des Utilisateurs : le personnel habilité des différents services d’Eco CO2 (le personnel habilité des services communication, administratif, logistique et informatique, chargés de traiter la relation client) ; les sous-traitants d’Eco CO2 qui agissent au nom et pour le compte et sur instruction d’Eco CO2.
        <br/><br/>
        Les données ne sont communiquées, échangées, vendues ou louées à aucune autre personne que celles mentionnées ci-dessus.
      </p>

      <h2>ARTICLE 5. DUREE DE CONSERVATION DES DONNEES</h2>

      <p>
        Eco CO2 s’engage à ce que les données collectées soient conservées sous une forme permettant l’identification de l’utilisateur pendant une durée qui n’excède pas la durée nécessaire à la finalité pour laquelle ces données sont collectées et traitées.
        <br/><br/>
        Cependant, le traitement des données est possible pour la preuve d’un droit ou d’un contrat. Ces données peuvent également être conservées dans l’objectif de respecter une obligation légale ou gardées dans des fichiers conformément aux règlements et lois applicables.
        <br/><br/>
        Dans le cadre d’une relation commerciale, les données sont conservées trois (3) ans à compter de la fin de la relation commerciale si l’Utilisateur est un client. Au-delà les données sont archivées pour la période où une action en justice peut être intentée.
        <br/><br/>
        Pour la gestion des demandes de droits de l’Utilisateur sur les données personnelles : les données sont conservées le temps de traiter ladite demande et au plus, six (6) mois.
        <br/><br/>
        S’agissant des cookies il est précisé que les informations stockées dans le terminal de l’Utilisateur ou tout autre élément utilisé pour vous identifier à des fins de statistiques d’audience ne sont pas conservées au-delà d’une période de treize (13) mois. Au-delà de ce délai, les données de fréquentation brutes associées à un identifiant sont soit supprimées soit anonymisées.
      </p>

      <h2>ARTICLE 6. VOS DROITS</h2>

      <p>
        Conformément au Règlement (UE) 2016/679 du Parlement européen et du Conseil du 27 avril 2016 relatif à la protection des personnes physiques à l’égard du traitement des données à caractère personnel et à la libre circulation de ces données, vous disposez des droits suivants : le droit d’accès, de rectification de vos données, d’effacement de vos données, droit de retirer à tout moment votre consentement, droit à la limitation du traitement de vos données, droit d’opposition au traitement de vos données et droit à la portabilité des données que vous nous avez fournies.
        <br/><br/>
        Vous avez également le droit d’introduire une réclamation auprès de la CNIL.
        <br/><br/>
        Ces droits peuvent être exercés, via une demande par courrier électronique à l’adresse ccil@ecoco2.com ou par courrier à Eco CO2 – Délégué à la protection des données, 3 bis rue du Docteur Foucault 92000 en indiquant ses coordonnées (nom, prénom, adresse et d’une copie d’une pièce d’identité).
        <br/><br/>
        En cas de communication de copie de pièce d’identité pour justifier de votre identité, nous la conserverons uniquement le temps nécessaire pour répondre à votre demande dans le cadre de l’exercice d’un de vos droits.
      </p>

      <h2>ARTICLE 7. DELEGUE A LA PROTECTION DES DONNEES</h2>

      <p>
        Pour toute demande d’information concernant la politique de protection des données à caractère personnel du SITE vous pouvez vous adresser à notre Délégué à la Protection des Données, y compris pour l’exercice de vos droits, relatifs à vos données à caractère personnel.
        <br/><br/>
        Vous pouvez le joindre à l’adresse ccil@ecoco2.com, ou par courrier à Eco CO2 – Délégué à la protection des données, 3 bis rue du Docteur Foucault 92000.
      </p>

      <h2>ARTICLE 8. TRANSFERTS HORS UE</h2>

      <p>
        Eco CO2 s’engage à ne pas transférer les données des Utilisateurs en dehors de l’Union Européenne.
        <br/><br/>
        Dans l’hypothèse où Eco CO2 mettrait en place des transferts de données hors de l’Union Européenne, Eco CO2 s’engage à en informer les Utilisateurs en indiquant les mesures prises afin de contrôler ce transfert et de s’assurer du respect de la confidentialité de leurs données.
      </p>
      <br/><br/>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: 'PrivacyNotice'
}
</script>

<style lang="scss">
  @import '../styles/theme.scss';

  .privacy {
    h1 {
      color: white !important;
      font-family: 'cookiesregular', Arial, sans-serif;
      margin-top: $margin-xxl;
      margin-bottom: $margin-m;
      padding-top: $margin-xs;
      padding-bottom: $margin-xs;
      font-size: 16px;
      padding-left: $margin-l;
    }

    :not(h2):not(h1):not(ul) {
      padding-left: $margin-l;
    }

    ul {
      margin-left: $margin-l;
    }

    h1:nth-of-type(5n+0) {
      background-color: #EC7309;
    }

    h1:nth-of-type(5n+1) {
      background-color: #BBCE31;
    }

    h1:nth-of-type(5n+2) {
      background-color: #5EBFC3;
    }

    h1:nth-of-type(5n+3) {
      background-color: #00525C;
    }

    h1:nth-of-type(5n+4) {
      background-color: #EC7309;
    }

    h2 {
      color: #BBCE31;
    }
  }
</style>
