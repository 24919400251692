<template>
    <div class="green-title-background green-border green-bottom-border" :class="{'full': full}">
        <div class="green-title" v-html="title" />
    </div>
</template>

<script>

export default {
  data () { return {} },
  name: 'greenTitle',
  props: {
    title: { type: String },
    full: { type: Boolean, default: false }
  }
}

</script>

<style lang="scss">
    .green-title-background {
      background: #BBCF31;
      transform: matrix(0.99, -0.12, 0.12, 0.99, 0, 0);
      width: 30%;
    }

    @media only screen and (max-width:959px) {
      .green-title-background {
        width: 100%;
      }
    }
    .green-title-background.full {
      width: 100%;
    }

    .green-title {
      text-align: center;
      font: normal normal normal 27px/32px 'cookiesregular';
      letter-spacing: 0px;
      color: white;
      padding: 20px;
    }

    .green-border:before {
        content: "";
        position: absolute;
        top: 10px;
        left: -10px;
        height: 100%;
        width: 100%;
        border-bottom: 10px solid #778721;
        border-left: 10px solid #778721;
    }
</style>
