<template>
  <v-layout column align-center xs12 class="blue-title">
    <h2>{{ title }}</h2>
  </v-layout>
</template>

<script>

export default {
  data () { return {} },
  name: 'blueTitle',
  props: ['title']
}

</script>

<style lang="scss">
  .blue-title {
    font-family: 'Roboto';
    background: #007789;
    text-align: center;
    font-weight: bold;
    padding-top: 50px;
    padding-bottom: 50px;
    h2 {
      font-size: 20px;
      text-transform: uppercase;
      background: #5EBFC3;
      font: normal normal normal 31px/37px 'cookiesregular';
            letter-spacing: 0px;
            color: #FFFFFF;
            width: 40%;
            border-radius: 10px;
            padding: 1em 0;
    }

        @media only screen and (max-width:959px) {
          h2 {
            width: 100%;
            border-radius: 0;
          }
        }

  }
</style>
