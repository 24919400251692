import Vue from 'vue'
import VueRouter from 'vue-router'

import HomePage from '../components/HomePage'
import ContestRules from '../components/ContestRules'
import HelpNotice from '../components/HelpNotice'
import ValidatePage from '../components/ValidatePage.vue'
import PrivacyNotice from '../components/PrivacyNotice.vue'
import LegalNotice from '../components/LegalNotice.vue'

// Routes
const routes = [
  { name: 'main', path: '/', component: HomePage },
  { name: 'rules', path: '/regles', component: ContestRules },
  { name: 'help', path: '/aide', component: HelpNotice },
  { name: 'validate', path: '/validation', component: ValidatePage },
  { name: 'privacy', path: '/politique-de-confidentialite', component: PrivacyNotice },
  { name: 'disclaimer', path: '/mentions-legales', component: LegalNotice }
]

// Router initialization
const router = new VueRouter({
  mode: 'history',
  routes,
  linkActiveClass: 'navmenu-active',
  scrollBehavior (to, from) {
    // If hash provided, process it and scroll to element's position
    if (to.path !== from.path) {
      if (to.hash) {
        return new Promise((resolve) => {
          // Wait for rendering to be finished
          Vue.prototype.$nextTick(() => {
            const el = document.querySelector(to.hash)
            const coordinates = el.getBoundingClientRect()
            const offset = coordinates ? coordinates.top : 0
            resolve({ x: 0, y: offset })
          })
        })
      }
      return { x: 0, y: 0 }
    }
  }
})

// Plugin injection
Vue.use(VueRouter)

export default router
