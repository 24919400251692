<template>
  <v-dialog
    v-if="submissions"
    v-model="dialog"
    lazy
    fullscreen>
    <div class="overlay" @click="dialog = false">
      <div>
        <v-layout row wrap justify-center>
          <v-flex xs12 sm7 @click.stop>
            <ParticipationCard
              v-if="submission && dialog"
              class="v-center content modal-fullscreen"
              :deletable="deletable"
              :participation="submission"
              display-original-file
              :likeable="isLikeAllowed"
              :liked="submissionLiked"
              fullscreen
            />
          </v-flex>
        </v-layout>
      </div>

      <v-btn  color="white" flat icon
              fixed top right class="close-btn"
              @click="dialog = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-btn  v-show="displayedItem > 0"
              fixed top left
              class="v-center"
              color="white" icon
              @click.stop="onPreviousClicked">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>

      <v-btn  class="v-center"
              fixed top right
              v-show="displayedItem < submissions.length - 1"
              color="white" icon
              @click.stop="onNextClicked">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </div>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import ParticipationCard from './ParticipationCard'

export default {
  components: {
    ParticipationCard
  },

  model: {
    prop: 'visible',
    event: 'change'
  },

  data () {
    return {
      dialog: false,
      displayedItem: 0
    }
  },

  props: {
    submissions: {
      type: Array,
      required: false
    },
    item: {
      type: Number,
      default: 0
    },
    visible: {
      type: Boolean,
      default: true
    },
    deletable: {
      type: Boolean,
      required: false,
      default: false
    },
    submissionLiked: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters({
      getContest: 'getContest'
    }),
    submission () {
      if (this.displayedItem < this.submissions.length) {
        return this.submissions[this.displayedItem]
      } else {
        return null
      }
    },
    isLikeAllowed () {
      const startDate = new Date(this.getContest.regional_vote_opening_date + 'T00:00')
      const endDate = new Date(this.getContest.regional_vote_closing_date + 'T00:00')
      // To include endDate
      endDate.setDate(endDate.getDate() + 1)
      const date = new Date()
      return startDate <= date && date < endDate
    }
  },

  methods: {
    onPreviousClicked () {
      this.$emit('update-prev', this.displayedItem - 1)
    },

    onNextClicked () {
      this.$emit('update-next', this.displayedItem + 1)
    }
  },

  watch: {
    visible (value) {
      this.dialog = value
    },

    dialog () {
      this.$emit('change', this.dialog)
    },

    item () {
      this.displayedItem = this.item
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../styles/theme.scss';

  .modal-fullscreen::v-deep .media { cursor: default; }

  .v-center {
    margin-top: 50vh;
    transform: translateY(-50%);
  }

  .content {
  }

  .h-center {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.6);
    overflow-y: hidden;
  }

  .close-btn {
    margin-top: $margin-m
  }
</style>
