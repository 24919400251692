<template>
  <v-layout class="text-xs-center" column self-align-start>
    <span class="date-desciption-image">
        <img :src="require('../../assets/' + nb + '.png')" />
    </span>
    <span class="date-description-title">{{ title }}</span>
    <span class="date-description-date">{{ date }}</span>
  </v-layout>
</template>

<script>
export default {
  props: {
    title: String,
    date: String,
    nb: Number
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/theme.scss';

  .date-description-image {
    float: center;
    margin-left: auto;
  }

  .date-description-title {
    font: normal normal normal 18px/24px Roboto;
    line-height: 1.5;
    display: block;
    margin-bottom: $margin-s;
  }

  .date-description-date {
    color: #5ABAC3;
    font-family: 'cookiesregular';
    font-weight: lighter;
  }
</style>
