<template>
<div>
  <BlueTitle title="Mes participations" />
  <v-container grid-list-xl>
    <v-layout justify-center row wrap>
      <v-flex xs12 sm6 md3 v-for="submission in ownSubmissions" :key="submission.id">
        <ParticipationCard
          @onimageclicked="openFullscreen(submission)"
          :participation="submission"
          :deletable="isDeletable()"
        />
      </v-flex>
    </v-layout>
  </v-container>
</div>
</template>

<script>
import ParticipationCard from './ParticipationCard'
import BlueTitle from '../BlueTitle'
import { mapState } from 'vuex'

export default {
  components: {
    ParticipationCard,
    BlueTitle
  },

  data () {
    return {
      fullscreenDisplay: false,
      fullscreenItem: 0
    }
  },

  computed: {
    ...mapState({
      contest: state => state.contest,
      ownSubmissions: state => state.submissions.ownSubmissions
    })
  },
  methods: {
    openFullscreen (submission) {
      submission.from = 'own'
      this.$emit('onimageclicked', submission)
    },
    isDeletable () {
      if (this.contest) {
        const startDate = this.$moment(this.contest.regional_vote_opening_date)
        const date = this.$moment()
        return date.isBefore(startDate)
      }
      return false
    }
  }
}
</script>
