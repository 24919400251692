<template>
  <div class="parent">
    <v-layout row wrap justify-center align-center class="pa-4">
      <v-flex xs10 sm11>
        <span>
            <img
              class="preview-img"
              :src="dataUrl"/>
            <div>
              <v-btn flat icon dark @click="previous()">
                  <v-icon color="#EEEEEE">mdi-arrow-left</v-icon>
              </v-btn>
              <v-btn flat icon dark @click="next()">
                  <v-icon color="#EEEEEE">mdi-arrow-right</v-icon>
              </v-btn>
              <v-btn flat icon dark @click="$emit('rotate', currentFile)" v-if="isImage">
                  <v-icon color="#EEEEEE">mdi-rotate-left</v-icon>
              </v-btn>
              <v-btn flat icon dark v-if="isVideo" @click="selectThumbnail = true">
                  <v-icon color="#EEEEEE">mdi-camera</v-icon>
              </v-btn>
            </div>
        </span>
      </v-flex>
      <v-flex xs2 sm1>
        <v-btn  flat icon dark
                v-on:click="cancel()">
          <v-icon color="#EEEEEE">mdi-delete</v-icon>
        </v-btn>
      </v-flex>
    </v-layout>
    <VideoThumbnailSelector v-model="selectThumbnail"
       :file="file"
       :thumbnail="thumbnail"
       @setTime="setTime"
    />
  </div>
</template>

<script>
import filehelper from '../../helpers/filehelper.js'
import VideoThumbnailSelector from './VideoThumbnailSelector'

export default {
  components: {
    VideoThumbnailSelector
  },

  data () {
    return {
      currentFile: 0,
      selectThumbnail: false
    }
  },

  props: {
    /**
     * Array of the file objects
     */
    files: {
      type: Array
    },
    /**
     * Array of the thumbnails to display
     */
    thumbnails: {
      type: Array
    },
    /**
     * A container to store the generated thumbnail
     */
    value: {
      type: Object
    }
  },

  computed: {
    hasCurrentFile () {
      return this.files && this.files.length && this.files[this.currentFile] !== null
    },

    isImage () {
      return this.hasCurrentFile && filehelper.isImage(this.files[this.currentFile])
    },

    isVideo () {
      return this.hasCurrentFile && filehelper.isVideo(this.files[this.currentFile])
    },

    dataUrl () {
      if (this.hasCurrentFile) {
        return this.thumbnails[this.currentFile].dataUrl
      }
      return null
    },

    file () {
      if (this.hasCurrentFile) {
        return this.files[this.currentFile]
      }
      return null
    },

    thumbnail () {
      if (this.hasCurrentFile) {
        return this.thumbnails[this.currentFile].dataUrl
      }
      return null
    }

  },

  methods: {
    /**
     * Emits a cancel event
     */
    cancel () {
      this.$emit('cancel')
    },

    previous () {
      if (this.currentFile !== 0) {
        this.currentFile -= 1
      } else {
        this.currentFile = this.files.length - 1
      }
    },

    next () {
      if (this.currentFile !== this.files.length - 1) {
        this.currentFile += 1
      } else {
        this.currentFile = 0
      }
    },

    setTime (value) {
      this.selectThumbnail = false
      this.$emit('setTime', { index: this.currentFile, time: value })
    }
  }
}
</script>

<style lang="scss">
@import '../../styles/theme.scss';

.parent {
  width: 100%;
  padding: 0 $margin-l;

  img {
    max-width: 100%;
    height: 230px;
    object-fit: contain;
    @media screen and (max-width: $screen_sm) {
      margin-top: 0;
    }
  }
  .pa-4 {
    @media screen and (max-width: $screen_sm) {
      padding-top: 0!important;
      padding-bottom: 0!important;
    }
  }

  .v-btn {
    margin: 12px 8px 0px 8px;
  }
}
.preview-img {
  border-radius: 8px;
}
.pa-4 {
  @media screen and (max-width: $screen_xs) {
    padding: 0!important;
  }
}
</style>
