import Vue from 'vue'
import moment from 'moment'

const plugin = {
  install () {
    moment.locale('fr')
    Vue.prototype.$moment = moment
  }
}
Vue.use(plugin)
