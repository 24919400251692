<template>
  <v-layout row wrap justify-center >
    <v-flex class="green-title" xs10 sm10 md6>
      <GreenTitle title="Gagnants Régionaux" />
    </v-flex>
    <v-flex xs12>
      <v-layout row wrap justify-center v-if="!loaded">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-layout>
      <v-layout row
                wrap
                justify-center
      >
        <template v-if="displayedRegions.length >= 1" >
          <v-flex xs3 hidden-sm-and-down>
            <v-select
              rounded
              :items="displayedRegions"
              v-model="selectedRegion"
              :item-text="item => item"
              :item-value="item => item"
              label="Afficher par région" />
          </v-flex>
          <v-flex xs10 hidden-md-and-up class="pt-5">
            <v-select
              rounded
              :items="displayedRegions"
              v-model="selectedRegion"
              :item-text="item => item"
              :item-value="item => item"
              label="Afficher par région" />
          </v-flex>
        </template>
        <v-flex xs12>
          <v-layout row wrap>
            <v-container grid-list-xl>
              <v-layout row wrap justify-center>
                <v-flex xs12 sm6 md3 v-for="(submission, index) in displayedSubmissions" :key="'submissionRank'+index">
                  <ParticipationCard
                    @onimageclicked="openFullscreen(submission)"
                    :participation="submission"
                    :likeable="false"
                    :title="typeAsLabel(submission.filetype)"
                  />
                </v-flex>
              </v-layout>
            </v-container>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import ParticipationCard from './ParticipationCard'
import { mapState, mapActions } from 'vuex'
import GreenTitle from '../GreenTitle'

export default {
  components: {
    ParticipationCard,
    GreenTitle
  },

  data () {
    return {
      loaded: false,
      selectedRegion: null,
      getWinnerSubmissions: null
    }
  },

  computed: {
    ...mapState({
      contest: state => state.contest,
      rank: state => state.rankRegional
    }),

    displayedRegions () {
      if (this.getWinnerSubmissions) {
        return Object.keys(this.getWinnerSubmissions)
      }
      return []
    },

    displayedSubmissions () {
      if (this.displayedRegions.length === 1) {
        return Object.values(this.getWinnerSubmissions)[0]
      }
      if (this.getWinnerSubmissions && this.selectedRegion) {
        return this.getWinnerSubmissions[this.selectedRegion]
      } else {
        return []
      }
    }
  },

  methods: {
    ...mapActions([
      'fetchRankRegional'
    ]),
    getDate (date) {
      return this.$moment(date).format('DD MMMM')
    },

    openFullscreen (submission) {
      submission.from = 'regional'
      submission.key = this.selectedRegion
      this.$emit('onimageclicked', submission)
    },

    typeAsLabel (typeValue) {
      return {
        video: 'Vidéo',
        model: 'Maquette',
        drawing: 'Dessin et/ou texte'
      }[typeValue]
    }
  },

  watch: {
    rank (ranks) {
      const ranksRegional = ranks.filter(item => {
        return item.winners.length > 0
      })
      const winners = Object.fromEntries(ranksRegional.map(region => {
        return [region.region, region.winners]
      }))
      this.loaded = true
      this.getWinnerSubmissions = Object.assign({}, winners)
    }
  },

  mounted () {
    this.fetchRankRegional()
  }

}
</script>
<style lang="css" scoped >
.green-title {
  display: flex;
  justify-content: center
}
</style>
