<template>
  <div>
    <v-layout row wrap>

      <v-flex xs12>
        <v-text-field
          v-model="search"
          placeholder="Rechercher un artiste ou une classe"
          prepend-icon="mdi-database-search"
        >
        </v-text-field>
      </v-flex>

      <v-flex xs12 md4>
        <v-select
          :item-text="item => item.displayedName"
          :item-value="item => item.name"
          :items="types"
          v-model="type"
          clearable
          label="Catégorie de réalisation" />
      </v-flex>

      <v-flex xs12 md4>
        <v-autocomplete
          :items="regionsList"
          v-model="region"
          :item-text="item => item.name"
          :item-value="item => item"
          clearable
          label="Région" />
      </v-flex>

      <v-flex xs12 md4>
        <v-autocomplete
          :items="townsList"
          v-model="town"
          label="Commune"
          :item-text="item => item.name"
          :item-value="item => item"
          clearable
          :disabled="!region" />
      </v-flex>

      <v-flex xs12 md4>
        <v-autocomplete
          :items="schoolsList"
          :item-text="item => item.name"
          :item-value="item => item"
          v-model="school"
          clearable
          :disabled="!town"
          label="Ecole" />
      </v-flex>

      <v-flex xs12 md4>
        <v-autocomplete
          :items="levelsList"
          v-model="level"
          clearable
          :item-text="item => item.name"
          :item-value="item => item"
          label="Niveau" />
      </v-flex>

    </v-layout>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

const DEFAULT_TYPES = [
  { name: 'drawing', displayedName: 'Dessin et/ou texte' },
  { name: 'model', displayedName: 'Maquette' },
  { name: 'video', displayedName: 'Vidéo' }
]

export default {
  data () {
    return {
      type: null,
      types: DEFAULT_TYPES,
      region: null,
      town: null,
      school: null,
      level: null,
      search: null
    }
  },

  computed: {
    ...mapState({
      regions: state => state.regions,
      levels: state => state.levels,
      allSubmissions: state => state.submissions.allSubmissions
    }),
    ...mapGetters([
      'getTowns',
      'getSchoolsFromTown'
    ]),

    regionsList () {
      if (this.regions) {
        return this.regions.filter(region => region.has_submission)
      }
      return []
    },

    townsList () {
      if (this.region) {
        const towns = this.getTowns(this.region)
        if (towns) {
          return towns.filter(town => town.has_submission)
        }
      }
      return []
    },

    schoolsList () {
      if (this.region && this.town) {
        const schools = this.getSchoolsFromTown(this.region, this.town)
        if (schools) {
          return schools.filter(school => school.has_submission)
        }
      }
      return []
    },

    levelsList () {
      if (this.levels && this.allSubmissions && this.allSubmissions.length) {
        const usedTypes = this.allSubmissions.map(submission => submission.level)
        return this.levels.filter(level => usedTypes.includes(level.name))
      } else if (this.levels) {
        return this.levels
      } else {
        return []
      }
    },

    choosenType () {
      if (this.type) {
        return this.type
        // return this.type.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")
      }
      return null
    },

    selectedRegion () {
      if (this.region) {
        return this.region.id
      }
      return null
    },

    selectedTown () {
      if (this.selectedRegion && this.town) {
        return this.town.id
      }
      return null
    },

    selectedSchool () {
      if (this.selectedRegion && this.selectedTown && this.school) {
        return this.school.id
      }
      return null
    },

    selectedLevel () {
      if (this.level) {
        return this.level.id
      }
      return null
    }
  },

  methods: {
    ...mapActions({
      fetchSubmissions: 'fetchSubmissions'
    }),
    paramsRequest () {
      const params = {
        filetype: this.choosenType,
        region: this.selectedRegion,
        commune: this.selectedTown,
        school: this.selectedSchool,
        level: this.selectedLevel,
        contest: 'last',
        search_text: this.search
      }
      this.fetchSubmissions(params)
    }
  },

  created () {
    this.paramsRequest()
  },

  watch: {
    type () {
      this.paramsRequest()
    },
    level () {
      this.paramsRequest()
    },
    region () {
      this.school = null
      this.town = null
      this.paramsRequest()
    },
    town () {
      this.school = null
      this.paramsRequest()
    },
    school () {
      this.paramsRequest()
    },
    search () {
      this.paramsRequest()
    }
  }

}
</script>
