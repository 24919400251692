<template>
  <div>
    <div id="contest">
      <ContestDescription
        v-if="hasContest"
        :contest="contest">
      </ContestDescription>
    </div>
    <!-- S'il y a pas de ranking aller dans contestUpload. Sinon, aller dans contestrank -->
    <div id="participate">
      <ContestUpload v-if="hasContest" :simplified="contest.simplified"/>
      <BlueTitle
        v-if="isResultNationalOpened || isResultRegionalOpened"
        title="Résultat du concours"
      />
      <ContestNationalRank
        v-if="isResultNationalOpened"
        @onimageclicked="openFullscreen"
      />
      <ContestRegionalRank
        v-if="isResultRegionalOpened"
        @onimageclicked="openFullscreen"
      />
    </div>
    <v-layout row wrap justify-center v-if="hasSubmission && (!isResultNationalOpened || !isResultRegionalOpened)">
      <v-flex xs12>
        <OwnSubmissionsList class="submissions-list" @onimageclicked="openFullscreen" />
      </v-flex>
    </v-layout>
    <SubmissionsList @onimageclicked="openFullscreen" />
    <FullscreenSubmission v-model="fullscreenDisplay"
                          :submissions="list"
                          :item.sync="fullscreenItem"
                          :submission-liked="isSubsmissionLiked(fullscreenItem)"
                          @update-prev="onSubmissionUpdatePrev"
                          @update-next="onSubmissionUpdateNext" />
  </div>
</template>

<script>
import ContestDescription from './Views/ContestDescription'
import ContestUpload from './Views/ContestUpload'
import ContestRegionalRank from './Views/ContestRegionalRank'
import ContestNationalRank from './Views/ContestNationalRank'
import OwnSubmissionsList from './Views/OwnSubmissionsList'
import SubmissionsList from './Views/SubmissionsList'
import FullscreenSubmission from './Views/FullscreenSubmission'
import BlueTitle from './BlueTitle'
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'HomePage',
  components: {
    ContestDescription,
    ContestUpload,
    ContestRegionalRank,
    ContestNationalRank,
    OwnSubmissionsList,
    SubmissionsList,
    FullscreenSubmission,
    BlueTitle
  },

  data () {
    return {
      fullscreenDisplay: false,
      fullscreenItem: 0,
      rankRegional: null,
      rankNational: null,
      list: null
    }
  },

  computed: {
    ...mapState([
      'contest',
      'ownSubmissions'
    ]),
    ...mapState({
      allSubmissions: state => state.submissions.allSubmissions,
      participationLikes: state => state.submissions.participationLikes,
      ranksRegional: state => state.rankRegional,
      ranksNational: state => state.rankNational,
      ownSubmissions: state => state.submissions.ownSubmissions
    }),
    ...mapGetters([
      'hasContest',
      'hasSubmission'
    ]),
    isResultRegionalOpened () {
      if (this.contest) {
        const startDate = this.$moment(this.contest.regional_results_publication_date)
        const date = this.$moment()
        return date.isAfter(startDate)
      }
      return false
    },
    isResultNationalOpened () {
      if (this.contest) {
        const startDate = this.$moment(this.contest.national_results_publication_date)
        const date = this.$moment()
        return date.isAfter(startDate) && !this.contest.simplified
      }
      return false
    },
    isRegionalVoteOpened () {
      if (this.contest) {
        const startDate = new Date(this.contest.upload_opening_date + 'T00:00')
        const endDate = new Date(this.contest.upload_closing_date + 'T00:00')
        // To include endDate
        endDate.setDate(endDate.getDate() + 1)
        const date = new Date()
        return startDate <= date && date < endDate
      }
      return false
    }
  },

  methods: {
    ...mapActions([
      'loadCodes',
      'fetchContests'
    ]),
    openFullscreen (submission) {
      this.list = this.allSubmissions
      if (submission.from === 'regional') {
        this.list = this.rankRegional[submission.key]
      } else if (submission.from === 'national') {
        this.list = this.rankNational
      } else if (submission.from === 'own') {
        this.list = this.ownSubmissions
      }

      let i = 0
      for (const item of this.list) {
        if (item.id === submission.id) {
          this.fullscreenItem = i
        }
        i++
      }

      this.fullscreenDisplay = true
    },
    getSubmissionLikedFlag (subId) {
      for (const like of this.participationLikes) {
        if (like.submission === subId) {
          return true
        }
      }

      return false
    },
    isSubsmissionLiked (idx) {
      const sub = this.getSubmissionByIndex(idx)
      if (sub) {
        return this.getSubmissionLikedFlag(sub.id)
      }
      return false
    },
    getSubmissionIndex (id) {
      for (const i in this.list) {
        if (this.list[i].id === id) {
          return parseInt(i)
        }
      }

      return null
    },
    getSubmissionById (id) {
      for (const i in this.allSubmissions) {
        if (this.allSubmissions[i].id === id) {
          return this.allSubmissions[i]
        }
      }

      return null
    },
    getSubmissionByIndex (index) {
      if (this.list && this.list[index]) {
        return this.list[index]
      }
      return null
    },
    onSubmissionUpdateNext (submission) {
      const sub = this.list[submission]
      this.$router.push({ name: 'main', query: { id: sub.id } })
    },
    onSubmissionUpdatePrev (submission) {
      const sub = this.list[submission]
      this.$router.push({ name: 'main', query: { id: sub.id } })
    }
  },

  watch: {
    /**
     * Watcher for general submissions that preloads a participation view
     * if its ID is present as query param. in the URL.
     *
     * @param  {Array} submissions - The submissions list.
     * @return {void}
     */
    allSubmissions (submissions) {
      const queryId = parseInt(this.$router.currentRoute.query.id) || null
      if (submissions && submissions.length && queryId) {
        // this.fullscreenItem = this.getSubmissionIndex(queryId)
        const submission = this.getSubmissionById(queryId)
        this.openFullscreen(submission)
        this.fullscreenDisplay = true
      }
    },
    ranksRegional (ranks) {
      if (ranks) {
        const rank = ranks.filter(item => {
          return item.winners.length > 0
        })
        this.rankRegional = Object.fromEntries(rank.map(region => {
          return [region.region, region.winners]
        }))
      }
    },

    ranksNational (ranks) {
      if (ranks) {
        this.rankNational = ranks.winners
      }
    },
    /**
     * Watcher used to update the URL query according to the selected participation.
     * The most common use case is when the user click on participations.
     *
     * @param  {boolean} value - The new 'fullsscreen' value.
     * @return {void}
     */
    fullscreenDisplay (value) {
      if (value) {
        this.$router.push({ name: 'main', query: { id: this.list[this.fullscreenItem].id } })
      } else {
        this.$router.push({ name: 'main' })
      }
    },
    /**
     * Route watcher used to update the view according to route updates.
     * The most common use case is when the user navigates through history.
     *
     * @param  {Object} currentRoute - The new route object.
     * @return {void}
     */
    $route (currentRoute) {
      const queryId = parseInt(currentRoute.query.id)
      if (queryId) {
        this.fullscreenItem = this.getSubmissionIndex(queryId)
      }
    }
  },

  mounted () {
    this.loadCodes(this.$route.query)
  }
}
</script>

<style lang="scss">
html body {
  background-color: white;
}
</style>
