<template>
  <form class="form" ref="uploadform">
    <div class="dropzone">
      <div class="input-container">
        <span class="input-instruction"
              :class="{ 'input-errors' : hasError }">{{ statusMsg }}</span>

        <label  for="fileinput"
                class="fileinput-label"></label>
        <input  type="file"
                class="fileinput"
                multiple
                id="fileinput"
                accept=".png, .jpeg, .jpg, .gif, image/jpeg, image/png, image/gif, .pdf, application/pdf, .mp4, .m4v, video/mp4"
                ref="file"
                v-on:change="handleFileUpload()"/>
      </div>
    </div>
  </form>
</template>

<script>
import helper from '../../helpers/filehelper.js'

export default {
  model: {
    prop: 'value',
    event: 'change'
  },

  props: {
    value: {
      type: Array
    }
  },

  data () {
    return {
      files: [],
      canDragAndDrop: false,
      isSizeValid: false,
      isExtensionValid: false
    }
  },

  computed: {
    errorMsg () {
      if (this.files.length) {
        if (!this.isExtensionValid) {
          return 'Ce format n\'est pas pris en charge (.jpg, .gif, .png, .pdf, .mp4 et .m4v acceptés).'
        }
        if (!this.isSizeValid) {
          return 'Le fichier est trop lourd. Consultez l\'aide pour en réduire la taille.'
        }
      }
      return null
    },

    statusMsg () {
      if (this.errorMsg) {
        return this.errorMsg
      }
      return 'Cliquez ici pour choisir un ou plusieurs fichiers ou glissez-le(s) directement dans la zone.'
    },

    hasError () {
      return this.files && (!this.isSizeValid || !this.isExtensionValid)
    }
  },

  methods: {
    handleFileUpload () {
      const formFiles = this.$refs.file.files
      if (formFiles.length > 0) {
        this.updateFiles(formFiles)
      }
    },

    getCanDragAndDrop () {
      const div = document.createElement('div')
      return (('draggable' in div) ||
          ('ondragstart' in div && 'ondrop' in div)) &&
          'FormData' in window &&
          'FileReader' in window
    },
    updateFiles (files) {
      this.isSizeValid = true
      this.isExtensionValid = true

      for (let i = 0; i < files.length; i++) {
        this.files.push(files[i])
        this.isSizeValid &= helper.isSizeValid(files[i])
        this.isExtensionValid &= helper.isVideo(files[i]) ||
                           helper.isImage(files[i]) ||
                           helper.isPdf(files[i])
      }

      if (this.isSizeValid && this.isExtensionValid) {
        this.$emit('change', this.files)
      }
    }
  },

  mounted () {
    this.canDragAndDrop = this.getCanDragAndDrop()
    if (this.canDragAndDrop) {
      /*
        Listen to all of the drag events and bind an event listener to each
        for the fileform.
      */
      ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach(function (evt) {
        this.$refs.uploadform.addEventListener(evt, function (e) {
          e.preventDefault()
          e.stopPropagation()
        }, false)
      }.bind(this))

      /*
        Add an event listener for drop to the form
      */
      this.$refs.uploadform.addEventListener('drop', e => {
        if (e.dataTransfer.files.length > 0) {
          this.updateFiles(e.dataTransfer.files)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/theme.scss';

.form {
  height: 50%;
  width: 100%;
}

.dropzone {
  padding: $margin-xxl;
  height: 100%;
  width: 100%;

  @media screen and (max-width: $screen_xs) {
    padding: 0;
    min-height: 200px;
  }

  .input-container {
    position: relative;
    height: 100%;
    width: 100%;
    border: medium dashed rgba(255, 255, 255, 0.50);

    &.errorBlock {
      background-color: white;
    }
    .fileinput-label {
      padding: $margin-xxl;
      display: block;
      height: 100%;
      width: 100%;
    }
    .input-instruction {
      pointer-events: none;
      position: absolute;
      display: block;
      padding: 0 $margin-xl;
      color: rgba(255, 255, 255, 0.50);
      top: 50%; /* poussé de la moitié de hauteur du référent */
      transform: translateY(-50%);

      &.input-errors {
        color: $primary;
        margin-bottom: 0;
        font-size: 20px;
      }
    }

    #fileinput {
      opacity: 0;
      position: absolute;
      z-index: -1;
    }
  }

}
</style>
