<template>
  <v-layout row wrap justify-center class="disclaimer">
    <v-flex xs12 sm6>
      <h1>MENTIONS LÉGALES</h1>

      <h2>1 &#8211; EDITION</h2>

      <p>
        Le présent site web, accessible à l’adresse www.ecoco2.com est le portail du groupe Eco CO2 et la propriété de la société Eco CO2 :
        <ul>
          <li>Capital social :  399 560 euros</li>
          <li>Forme sociale : SAS</li>
          <li>Immatriculée au RCS de Nanterre, sous le numéro 511 644 601</li>
          <li>TVA intracommunautaire : FR 92 511 644 601</li>
          <li>Siège social : 3 bis rue du Docteur Foucault, 92000, Nanterre</li>
          <li>Tél : +33 (0)9 72 59 04 78</li>
          <li>Courriel : webmaster@ecoco2.com</li>
        </ul>
        <br/>
        Le directeur de publication du portail du groupe Eco CO2 est Madame Johanna Le Conte, en qualité de Président de la société Eco CO2.
        <br/><br/>
        Le dispositif internet du groupe Eco CO2 constitué par les sociétés :
        <ul>
          <li>Eco CO2</li>
          <li>QUARTUM, SAS au capital de 100 000 euros, SIREN : 803 738 657 RCS Nanterre, 3 bis rue du Docteur Foucault, 92000, Nanterre</li>
          <li>Eco CO2 Holding, SAS au capital de 500 925 euros, SIREN 834 524 647 RCS Nanterre, 3 bis rue du Docteur Foucault, 92000, Nanterre</li>
        </ul>
        <br/>
        a été conçu et édité par la société Eco CO2.
        <br/><br/>
        Le présent site et les autres sites du portail du groupe Eco CO2 sont hébergés sur les matériels informatiques de la société OVH, SAS au capital de 500 000 euros, immatriculée au RCS de Roubaix &#8211; Tourcoing 424 761 419 &#8211; Siège social : 2 rue Kellermann 59100 Roubaix, accessible à www.ovh.com.
      </p>

      <h2>2 &#8211; CONTENU DES SITES DU PORTAIL</h2>

      <h3>2.1 Contenu</h3>

      <p>
        Le présent site donne accès à d’autres sites du portail de la société Eco CO2 ou de sociétés du groupe Eco CO2, susceptibles de disposer de leurs propres mentions légales, qu’il convient de consulter et de respecter. Les sociétés du groupe Eco CO2 ont leur propre existence légale et disposent d’une personnalité juridique autonome.
        <br/><br/>
        Les informations fournies sur le présent site et les autres sites du portail du groupe Eco CO2 (ci-après le « Site ») le sont à titre informatif et indicatif. Eco CO2 ne saurait garantir l’exactitude, la complétude, l’actualité des informations diffusées sur le site. Elles ne sauraient dispenser l’utilisateur d’une analyse complémentaire et adaptée. Eco CO2 ne saurait donc être tenu responsable des erreurs ou omissions, d’une absence de disponibilité des informations et des services, y compris l’inaccessibilité, les pertes de données, et/ou la présence de virus sur le site, sans que cette liste soit exhaustive.
        <br/><br/>
        En conséquence, l’utilisateur reconnaît utiliser ces informations sous sa responsabilité exclusive. Eco CO2 ne saurait être tenu pour responsable des dommages directs ou indirects qui pourraient résulter de l’accès ou de l’utilisation du portail du groupe Eco CO2.
      </p>

      <h3>2.2 Licence d’utilisation du contenu</h3>

      <p>
        Du seul fait de sa connexion au Site, l’utilisateur reconnaît accepter de Eco CO2, une licence d’usage du contenu du portail à titre non exclusif et non transmissible.
        <br/><br/>
        Le droit d’usage conféré à l’utilisateur est personnel et privé : toute reproduction du contenu du Site sur un quelconque support pour un usage collectif ou professionnel, même en interne dans l’entreprise, est prohibée.
        <br/><br/>
        Le droit d’usage comprend seulement l’autorisation de consulter le Site et son contenu (représentation sur écran monoposte, reproduction en un exemplaire, copie pour sauvegarde et tirage papier).
        <br/><br/>
        Tout autre usage est soumis à l’autorisation expresse préalable de Eco CO2. La violation de ces dispositions soumet le contrevenant et toutes personnes responsables aux peines pénales et civiles prévues par la loi française.
      </p>

      <h3>2.3 Liens hypertextes</h3>

      <p>
        Les liens hypertextes mis en place dans le cadre du Site en directions d’autres ressources présentes sur le réseau internet ne sauraient engager la responsabilité d’Eco CO2. Les utilisateurs du portail ne peuvent pas mettre en place un lien hypertexte en direction de ses sites sans l’autorisation expresse et préalable de la SAS Eco CO2.
      </p>

      <h3>2.4 Autorisation de reproduction de contenu</h3>

      <p>
        Pour toute information et/ou demande de reproduction d’un contenu du Site (texte, image, graphique, illustrations, photographie etc…) quel qu’en soit le support, comme pour toute autorisation de mise en place d’un hyperlien, l’utilisateur est invité à adresser sa demande d’autorisation écrite, expresse et préalable d’Eco CO2, en écrivant à l’adresse 3 bis rue du Docteur Foucault, 92000 Nanterre ou en envoyant un courriel à webmaster@ecoco2.com.
      </p>

      <h2>3 &#8211; INFORMATIQUE ET LIBERTÉS</h2>

      <p>
        La société Eco CO2 est le responsable de traitement des données collectées sur le présent site web et les autres sites du portail du groupe Eco CO2.
        <br/><br/>
        L’utilisateur est informé que conformément à la loi n° 2018-493 du 20 juin 2018 relative à la protection des données personnelles, les informations qu’il communique par les formulaires présents sur les sites du portail du groupe Eco CO2 sont nécessaires pour répondre à sa demande et sont destinées aux services en charge de répondre à sa demande à des fins de suivi de cette demande.
        <br/><br/>
        L’utilisateur bénéficie d’un droit d’accès, de rectification, de mise à jour et d’effacement des informations qui le concernent, qu’il peut exercer en s’adressant à ccil@ecoco2.com ou par courrier à Eco CO2 &#8211; DPO 3bis rue du Docteur Foucault 92000 Nanterre, en précisant dans l’objet du courrier « Droit des personnes » et en joignant la copie de son justificatif d’identité. L’utilisateur peut également s’opposer, pour des motifs légitimes à ce que ses données fassent l’objet d’un traitement et sans motif, à ce que ses données soient utilisées à des fins de prospection commerciale.
        <br/><br/>
        L’utilisateur est lui-même tenu de respecter les dispositions de la loi susvisée, dont la violation est passible de sanctions pénales.
        <br/><br/>
        Il doit notamment s’abstenir, s’agissant des informations nominatives auxquelles il accède, de toute collecte, de toute utilisation détournée, et d’une manière générale, de tout acte susceptible de porter atteinte à la vie privée ou à la réputation des personnes.
      </p>

      <h2>4 &#8211; COOKIES</h2>

      <p>
        Lors de ses visites sur le Site, l’utilisateur est informé qu’un témoin de connexion peut s’installer automatiquement sur son logiciel de navigation. Il s’agit d’un bloc de données qui n’est pas utilisé à des fins d’identification mais qui sert à enregistrer des informations anonymes relatives à la navigation de l’utilisateur navigation sur le Site. Il permet des analyses de fréquentation et des mesures d’audience afin d’améliorer la qualité du Site et permettre d’améliorer l’expérience d’utilisation.
        <br/><br/>
        Le paramétrage du logiciel de navigation permet d’informer de la présence de cookie et éventuellement, de la refuser.
      </p>

      <h2>5 &#8211; PROPRIÉTÉ INTELLECTUELLE</h2>

      <p>
        Les marques et les logos (marques semi-figuratives) d’Eco CO2 et des sociétés du groupe figurant sur le Site sont des marques déposées. Toute reproduction ou représentation totale ou partielle, seules ou intégrées à d’autres éléments, sans l’autorisation écrite, expresse et préalable d’Eco CO2 ou des sociétés du groupe Eco CO2 concernées, en est strictement interdite. La structure générale, les logiciels, textes, images, vidéos, sons, savoir-faire, animations, et plus généralement toutes les informations et contenus figurant dans le portail du groupe Eco CO2, sont la propriété d’Eco CO2 ou font l’objet d’un droit d’utilisation ou d’exploitation. Ces éléments sont soumis à la législation française et européenne protégeant le droit d’auteur.
        <br/><br/>
        Toute représentation, modification, reproduction, dénaturation, totale ou partielle, de tout ou partie du site ou de son contenu, par quelque procédé que ce soit, et sur quelque support que ce soit constituerait une contrefaçon sanctionnée par les articles L 335-2 et suivants du code de la Propriété Intellectuelle.
        <br/><br/>
        Les bases de données figurant, le cas échéant, sur le portail du groupe Eco CO2 sont protégées par les dispositions de la loi du 1er juillet 1998 transposant dans le code de la propriété intellectuelle, la Directive européenne du 11 mars 1996 relative à la protection juridique des bases de données. A ce titre, Eco CO2 interdit expressément toute réutilisation, reproduction ou extraction d’éléments de ces bases de données. La réutilisation, reproduction ou extraction non autorisée engage la responsabilité de l’utilisateur.
        <br/><br/>
        Eco CO2 se réserve la faculté de supprimer sans délais, et sans mise en demeure préalable, tout contenu : message, texte, image, graphique qui contreviendrait aux lois et règlements en vigueur et notamment les réglementations précisées ci-dessus.
      </p>

      <h2>6 &#8211; GESTION DES IDENTIFIANTS ET MOT DE PASSE</h2>

      <p>
        Certains services interactifs présents sur le portail du groupe Eco CO2 nécessitent un identifiant et un mot de passe qui sont fournis par les gestionnaires de ces services. Ces codes d’accès et mots de passe sont confidentiels, personnels, incessibles et intransmissibles. L’utilisateur est responsable de leur gestion, conservation et des conséquences de leur utilisation. Il appartient à l’utilisateur de prendre les dispositions nécessaires à leur protection et à leur conservation. Eco CO2 ne pourra en aucun cas être tenu pour responsable d’une utilisation frauduleuse.
        <br/><br/>
        Pour des raisons de sécurité, l’accès aux rubriques sécurisées sera automatiquement invalidé à l’issue de plusieurs tentatives d’accès erronées. Eco CO2 se réserve le droit de suspendre l’accès au Site en cas d’utilisation frauduleuse ou de tentative d’utilisation frauduleuse du mot de passe ou du code d’accès d’un utilisateur. En cas de suspension de l’accès, EcoCO2 en informera l’utilisateur.
      </p>

      <h2>7 &#8211; DROIT APPLICABLE</h2>

      <p>
        Le droit applicable au portail du groupe Eco CO2 est le droit français. En cas de litige résultant de votre utilisation de nos sites web, seuls les Tribunaux français seront compétents.
        <br/><br/>
        Certains des contenus et services en ligne sur le portail du groupe Eco CO2 peuvent être proposés en français et en anglais. En cas de litige, c’est la langue française qui fera foi.
      </p>

      <br/><br/>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: 'LegalNotice'
}
</script>

<style lang="scss">
  @import '../styles/theme.scss';

  .disclaimer {
    h1 {
      color: white !important;
      font-family: 'cookiesregular', Arial, sans-serif;
      margin-top: $margin-xxl;
      margin-bottom: $margin-m;
      padding-top: $margin-xs;
      padding-bottom: $margin-xs;
      font-size: 16px;
      padding-left: $margin-l;
    }

    :not(h2):not(h1):not(ul) {
      padding-left: $margin-l;
    }

    ul {
      margin-left: $margin-l;
    }

    h1:nth-of-type(5n+0) {
      background-color: #EC7309;
    }

    h1:nth-of-type(5n+1) {
      background-color: #BBCE31;
    }

    h1:nth-of-type(5n+2) {
      background-color: #5EBFC3;
    }

    h1:nth-of-type(5n+3) {
      background-color: #00525C;
    }

    h1:nth-of-type(5n+4) {
      background-color: #EC7309;
    }

    h2 {
      color: #BBCE31;
    }
  }
</style>
