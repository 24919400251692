<template>
  <v-app color="white">
    <MainMenu />
    <v-content app>
      <router-view />
    </v-content>
    <AppFooter />
  </v-app>
</template>

<script>
import MainMenu from './components/Views/MainMenu'
import { mapActions } from 'vuex'
import AppFooter from './components/AppFooter'

export default {
  name: 'App',
  components: {
    MainMenu,
    AppFooter
  },
  data () {
    return {
      active: null
    }
  },

  methods: {
    ...mapActions([
      'fetchContests'
    ])
  },

  mounted () {
    this.fetchContests()
  }
}
</script>

<style lang="scss">
.theme--light.application {
  background: white !important;
}
</style>
