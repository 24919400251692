<template>
<div>
  <v-layout row wrap justify-center>
    <v-flex xs12 sm6>
      <div class="help" v-html="formattedHelp">
      </div>
    </v-flex>
  </v-layout>
  <v-layout row wrap justify-center>
    <v-flex xs12 sm2>
      <img class="align-center" :src="require('../assets/2004-watty-au-revoir-22.svg')" />
    </v-flex>
  </v-layout>
  </div>
</template>

<script>
import marked from 'marked'
import help from '../assets/help.md'

export default {
  name: 'HelpNotice',
  computed: {
    formattedHelp () {
      return marked(help, { sanitize: true })
    }
  }
}
</script>

<style lang="scss">
  @import '../styles/theme.scss';

  .help {
    h1 {
      color: #BBCF31;
      font: normal normal bold 28px/37px Roboto;
      letter-spacing: 0px;
      margin-top: $margin-xxl;
      margin-bottom: $margin-m;
      padding-top: $margin-xs;
      padding-bottom: $margin-xs;
      padding-left: $margin-l;
    }

    a {
      padding-left: 0;
    }

    :not(h2):not(h1):not(ul):not(a) {
      padding-left: $margin-l;
    }

    ul {
      margin-left: $margin-l;
    }
  }
</style>
