export default {
  state: {
    /**
     * Contains the progress event returned from axios
     */
    currentUploadProgressEvent: null,
    /**
     * Contains true at the end of the upload.
     * Is reset to false when a new upload starts
     */
    currentUploadDone: false,
    /**
     * Contains an object with boolean values
     * when an error occurs.
     * Is reset to null when a new upload starts
     */
    currentUploadError: null
  },

  mutations: {
    setCurrentUploadProgressEvent (state, pe) {
      state.currentUploadProgressEvent = pe
    },

    setCurrentUploadDone (state, done) {
      state.currentUploadDone = done
    },

    setCurrentUploadError (state, error) {
      state.currentUploadError = error
    },

    resetUpload (state) {
      state.currentUploadProgressEvent = null
      state.currentUploadDone = null
      state.currentUploadError = null
    }
  },

  actions: {
    /**
     * Sends a paricipation to the back-end.
     *
     * @param {Object} data the data to send, in the following form :
     * {
     *   school: the school parameters
     *   file: the input file
     *   artist: the artist name
     *   thumbnail: the generated thumbnail
     * }
     *
     */
    submitParticipation ({ commit, dispatch, rootState }, data) {
      return new Promise((resolve, reject) => {
        // First create the level, and use it to submit the participation
        const levelCreationData = {
          school: data.school,
          level: data.level,
          suffix: data.suffix
        }

        commit('setCurrentUploadError', null)
        commit('setCurrentUploadDone', false)
        commit('setCurrentUploadProgressEvent', null)
        this.axios.post('/participants/classes/', levelCreationData)
          .then(response => {
            const formdata = new FormData()
            data.files.forEach((file) => {
              formdata.append('files', file)
            })
            formdata.set('filetype', data.filetype)
            formdata.set('artist', data.artist ? data.artist : '')
            formdata.set('klass', response.data.id)
            formdata.set('read', data.isRead)
            data.thumbnails.forEach((thumbnail) => {
              // eslint-disable-next-line
                console.log('thumbnail = ', thumbnail)
              formdata.append('thumbnails', thumbnail.dataBlob, thumbnail.name)
              formdata.append('rotations', thumbnail.rotation)
            })
            formdata.set('can_be_used', data.canBeUsed)
            formdata.set('is_legal_representative', data.isLegalRepresentative)
            if (rootState.writeCode) {
              formdata.append('code', rootState.writeCode)
            }
            // eslint-disable-next-line
            console.log('formdata = ', formdata)
            const config = {
              method: 'post',
              url: '/submissions/',
              data: formdata,
              timeout: 0,
              config: { headers: { 'Content-Type': 'multipart/form-data' } },
              onUploadProgress: progressEvent => {
                commit('setCurrentUploadProgressEvent', progressEvent)
              }
            }

            this.axios(config).then(submitResponse => {
              commit('setReadCode', submitResponse.data.codes.read)
              commit('setWriteCode', submitResponse.data.codes.write)
              dispatch('fetchOwnSubmissions')
              commit('setCurrentUploadDone', true)
              resolve(submitResponse)
            })
              .catch(error => {
                let errorMessage = ''
                if (error.response.status === 400) {
                  if (error.response.data.artist) {
                    errorMessage = error.response.data.artist[0].message
                  } else {
                    errorMessage = error.response.data[0].message
                  }
                }
                commit('setCurrentUploadError', errorMessage)
                commit('setCurrentUploadDone', true)
                reject(error)
              })
          })
          .catch(error => {
            // eslint-disable-next-line
            console.log(error.response)
            commit('setCurrentUploadError', "Erreur lors de l'enregistrement de la classe")
            commit('setCurrentUploadDone', true)
            reject(error)
          })
      })
    }
  }
}
