import axios from 'axios'

const ls = window.localStorage

const PAGE_SIZE = 50
export default {
  state: {
    submissions: null,
    allSubmissions: null,
    ownSubmissions: null,
    participationLikes: [],
    setCurrentDeleteError: null,
    randomSeed: null,
    lastParams: null,
    submissionsCancelToken: null
  },
  getters: {
    hasSubmission (state) {
      if (state.ownSubmissions) {
        return !!state.ownSubmissions.length
      }
      return false
    }
  },

  mutations: {
    setAllSubmissions (state, submissions) {
      if (state.allSubmissions) {
        state.allSubmissions = [...state.allSubmissions, ...submissions]
      } else {
        state.allSubmissions = submissions
      }
    },
    setSubmissionsCancelToken (state, token) {
      state.submissionsCancelToken = token
    },
    resetSubmissions (state) {
      state.allSubmissions = null
    },
    setOwnSubmissions (state, submissions) {
      state.ownSubmissions = submissions
    },
    setParticipationLikes (state, likes) {
      state.participationLikes = likes
    },
    setCurrentDeleteError (state, error) {
      state.setCurrentDeleteError = error
    },
    setLastParams (state, params) {
      state.lastParams = params
    },
    setRandomSeed (state) {
      if (!state.randomSeed) {
        state.randomSeed = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER)
      }
    }
  },

  actions: {
    fetchOwnSubmissions ({ commit, rootState }) {
      const requestParams = {
        code: rootState.readCode
      }
      this.axios.get('/submissions/', { params: requestParams })
        .then(response => {
          if (requestParams.code) {
            commit('setOwnSubmissions', response.data)
          }
        })
    },
    loadNextSubmissions ({ commit, state, dispatch }, params) {
      let queryParams = { ...params }

      if (state.lastParams) {
        queryParams = { ...state.lastParams }
      } else {
        commit('setLastParams', params)
      }
      const page = state.allSubmissions ? Math.floor(state.allSubmissions.length / PAGE_SIZE) + 1 : 1

      queryParams.page = page

      dispatch('fetchSubmissions', queryParams)
    },
    fetchSubmissions ({ commit, state, dispatch }, params) {
      commit('setRandomSeed')

      const requestParams = params || {}
      requestParams.seed = state.randomSeed

      let source = state.submissionsCancelToken

      commit('setLastParams', requestParams)
      if (!requestParams.page) {
        if (source) {
          source.cancel()
        }
        const CancelToken = axios.CancelToken
        source = CancelToken.source()
        commit('setSubmissionsCancelToken', source)
        commit('resetSubmissions')
      }

      this.axios.get('/submissions/', { params: requestParams, cancelToken: source ? source.token : null })
        .then(response => {
          const receivedIds = []
          response.data.forEach(element => {
            receivedIds.push(element.id)
          })

          commit('setAllSubmissions', response.data)
          if (response.data.length === PAGE_SIZE) {
            dispatch('loadNextSubmissions')
          }
        }).catch((thrown) => {
          if (axios.isCancel(thrown)) {
            // Do nothing
          }
        })
    },
    deleteParticipation ({ dispatch, rootState }, participationId) {
      const params = {
        code: rootState.writeCode
      }
      const URL = '/submissions/' + participationId + '/'
      this.axios.delete(URL, { params })
        .then(() => {
          dispatch('fetchOwnSubmissions')
        })
        .catch(() => {
          // nothing to do yet
        })
    },
    fetchParticipationLikes ({ commit }) {
      const url = '/votes/'
      const uuid = ls.getItem('watty-uuid')
      const params = {}
      if (uuid) {
        params.uuid = uuid
      }

      this.axios.get(url, { params })
        .then(response => {
          commit('setParticipationLikes', response.data)
        })
    },
    likeParticipation ({ dispatch }, participationId) {
      const url = '/votes/'
      const uuid = ls.getItem('watty-uuid')
      const data = { submission: participationId }
      if (uuid) {
        data.uuid = uuid
      }

      this.axios.post(url, data)
        .then(response => {
          // Store the received UUID in LS if not already present
          if (!ls.getItem('watty-uuid')) {
            ls.setItem('watty-uuid', response.data.uuid)
          }
          dispatch('fetchParticipationLikes')
        })
        .catch(() => {
          // nothing to do yet
        })
    },
    unlikeParticipation ({ dispatch }, participationId) {
      const url = '/votes/'
      const uuid = ls.getItem('watty-uuid')
      const params = { submission: participationId }
      if (uuid) {
        params.uuid = uuid
      }
      this.axios.delete(url, { params })
        .then(() => {
          dispatch('fetchParticipationLikes')
        })
        .catch(() => {
          // nothing to do yet
        })
    }
  }
}
