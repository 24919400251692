<template>
    <div class="parent">
      <v-autocomplete
            :items="displayedRegions"
            v-model="selectedRegion"
            dark
            :item-text="item => item.name"
            :item-value="item => item"
            label="Région" />
      <v-autocomplete
            :disabled="!selectedRegion"
            :items="towns"
            dark
            :item-text="item => item.name"
            :item-value="item => item"
            v-model="selectedTown"
            label="Commune" />
      <v-autocomplete
            :disabled="!selectedTown"
            :items="schools"
            :item-text="item => item.name"
            :item-value="item => item"
            v-model="selectedSchool"
            dark
            label="Ecole" />
      <v-autocomplete
          :disabled="!selectedSchool"
          :items="levels"
          :item-text="item => item.name"
          :item-value="item => item"
          v-model="selectedLevel"
          dark
          label="Niveau"
      ></v-autocomplete>
      <v-text-field
            :disabled="!selectedLevel"
            label="Nom de l'enseignant(e) de la classe"
            dark
            maxlength="30"
            v-model="klassName"/>
      <v-checkbox
            :disabled="!klassName || simplified"
            label="Réalisation de classe entière"
            dark
            v-model="classWork" />
      <v-text-field
          v-if="!isChecked"
          :disabled="classWork || !klassName || simplified"
          dark
          v-model="artist"
          label="Prénom et nom de l'artiste"
      ></v-text-field>
      <p class="align-left subheading">
          Eco CO2 peut être amené à utiliser cette réalisation, de façon anonyme, sur des supports
          de communication pédagogiques et promotionnels afin de valoriser les créations artistiques du concours Watty à l’école.
          Pour plus de précisions, veuillez consulter <router-link :to="{ name: 'rules' }">le règlement du concours</router-link>.
      </p>
      <v-checkbox
      class="pa-0 ma-0 custom-checkbox caption"
        v-model="authorized"
        label="J'atteste avoir envoyé une oeuvre originale et avoir respecté les droits d'auteur"
        dark
        />
        <v-checkbox
         class="pa-0 ma-0 custom-checkbox caption"
         label="J'ai lu et j'accepte le règlement du concours"
         dark
         v-model="isRead" />
         <v-checkbox
         class="pa-0 ma-0 custom-checkbox caption"
         label="Je certifie être le représentant légal ou l’enseignant ou l’animateur périscolaire de(s) élève(s) participant(s)"
         dark
         v-model="isLegalRepresentative" />
      <v-checkbox
        class="pa-0 ma-0 custom-checkbox caption"
        label="J'autorise Eco CO2 à utiliser cette réalisation à des fins de communication (facultatif)"
        dark
        v-model="canBeUsed">
      </v-checkbox>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  model: {
    prop: 'value',
    event: 'change'
  },
  data () {
    return {
      selectedRegion: null,
      selectedTown: null,
      selectedSchool: null,
      klassName: '',
      classWork: false,
      artist: null,
      selectedLevel: null,
      canBeUsed: false,
      isRead: false,
      authorized: false,
      isLegalRepresentative: false
    }
  },

  props: {
    value: {
      type: Object,
      required: false
    },
    simplified: {
      type: Boolean,
      required: true
    },
    uploadAgain: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapState([
      'regions',
      'levels'
    ]),
    ...mapGetters([
      'getTowns',
      'getSchoolsFromTown'
    ]),
    displayedRegions () {
      return this.regions
    },
    towns () {
      if (this.selectedRegion) {
        return this.getTowns(this.selectedRegion)
      } else {
        return []
      }
    },
    isChecked () {
      return this.simplified || this.classWork
    },
    schools () {
      if (this.selectedRegion && this.selectedTown) {
        return this.getSchoolsFromTown(this.selectedRegion, this.selectedTown)
      } else {
        return []
      }
    },
    displayedLevels () {
      return this.levels.map(item => item.name)
    },
    returnObject () {
      return {
        region: this.selectedRegion,
        town: this.selectedTown,
        school: this.selectedSchool,
        klass: this.klassName,
        classWork: this.isChecked,
        level: this.selectedLevel,
        artist: this.isChecked ? null : this.artist,
        canBeUsed: this.canBeUsed,
        authorized: this.authorized,
        isRead: this.isRead,
        isLegalRepresentative: this.isLegalRepresentative
      }
    }
  },
  watch: {
    isRead (value) {
      if (!value) {
        this.$emit('change')
      }
    },
    authorized (value) {
      if (!value) {
        this.$emit('change')
      }
    },
    isLegalRepresentative (value) {
      if (!value) {
        this.$emit('change')
      }
    },
    uploadAgain: {
      handler () {
        if (this.uploadAgain) {
          this.isRead = false
          this.authorized = false
          this.canBeUsed = false
          this.isLegalRepresentative = false
          this.$emit('setUploadAgainToFalse')
        }
      }
    },
    simplified: {
      handler () {
        if (this.simplified) {
          this.classWork = true
        }
      },
      immediate: true
    },
    selectedRegion () {
      this.selectedTown = null
      this.selectedSchool = null
      this.klassName = ''
    },
    selectedTown () {
      this.selectedSchool = null
      this.klassName = ''
    },
    returnObject (value) {
      if (value.region !== null &&
          value.collectivity !== null &&
          value.school !== null &&
          value.authorized &&
          value.isRead && value.isLegalRepresentative &&
          ((value.artist != null || value.classWork) || this.simplified)) {
        this.$emit('change', this.returnObject)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/theme.scss';

.custom-checkbox {
 margin-bottom: -15px !important;
 font-size: 12px !important;
}
.parent {
  width: 100%;
}
.align-left {
  text-align: left;
}

</style>
