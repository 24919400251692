export default {
  state: {
    participationToValidate: null,
    validationToken: null
  },

  getters: {
    getValidationToken (state) {
      return state.validationToken
    }
  },

  mutations: {
    setParticipationToValidate (state, item) {
      state.participationToValidate = item
    },

    setValidationToken (state, token) {
      state.validationToken = token
    }
  },

  actions: {
    fetchParticipationToValidate ({ commit, state }) {
      const options = {
        url: '/moderation/submissions/',
        method: 'get',
        headers: { Authorization: 'Token ' + state.validationToken }
      }
      this.axios(options)
        .then(response => {
          commit('setParticipationToValidate', response.data)
        })
        .catch(() => {
          commit('setParticipationToValidate', null)
        })
    },

    setValidation ({ state, dispatch }, data) {
      const id = data.id
      const validated = data.validated
      const options = {
        url: '/moderation/submissions/' + id + '/',
        method: 'patch',
        headers: { Authorization: 'Token ' + state.validationToken },
        data: { validated }
      }
      this.axios(options)
        .then(() => {
          dispatch('fetchParticipationToValidate')
        })
    }
  }
}
