<template>
  <v-footer
    height="auto"
    color="#BBCF32"
    class="cW-footer"
  >
    <v-layout row wrap align-center justify-center>
        <v-flex class="pa-3 right-border" column xs12 sm6>
         <strong>Watty à l’école</strong> est un programme proposé par la société <strong>Eco CO2</strong>.
         <strong>Eco CO2</strong> crée des solutions pour accélérer la transition écologique par le changement des comportements des citoyens et des organisations.
         Depuis 2009, l’éco-entreprise déploie des programmes d’accompagnement sur les économies d’énergie et la mobilité durable,
         conçoit des solutions connectées et réalise des études sur la conduite du changement et la maîtrise de l’énergie.
        </v-flex>

       <v-flex class="pa-4 right-border" column xs12 sm2>
        <div class="white--text">Allez plus loin sur la toile !</div>
        <div class="py-1">
          <a class="black--text bold-link no-decoration-link" href="https://www.ecoco2.com/" target="_blank">Eco CO2</a>
        </div>
        <div class="py-1">
          <a class="black--text bold-link no-decoration-link" href="https://www.moby-ecomobilite.fr/" target="_blank">Moby, l'écomobilité scolaire</a>
        </div>
        <div class="py-1">
          <a class="black--text bold-link no-decoration-link" href="https://www.watty.fr/" target="_blank">Watty</a>
        </div>
       </v-flex>

       <v-flex class="pa-4" column xs12 sm2>
        <div class="py-1">
          <router-link class="black--text no-decoration-link" :to="{ name: 'privacy' }">
            Politique de confidentialité
          </router-link>
        </div>
        <div class="py-1">
          <router-link class="black--text no-decoration-link" :to="{ name: 'disclaimer' }">
            Mentions légales
          </router-link>
        </div>
       </v-flex>

    </v-layout>
  </v-footer>
</template>

<script>
export default {
  name: 'AppFooter'
}
</script>

<style lang="scss">
@import '../styles/theme.scss';
.cW-footer {

  a {
    color: #fff;
  }
  .right-border {
    position: relative;
    :before {
      content: "";
      position: absolute;
      right: 0;
      top: 15px;
      height: 80%;
      border-right: 1px solid #77862B;
    }
  }
}
.no-decoration-link {
  text-decoration: none !important;
}
.bold-link {
  font-weight: bold;
}
</style>
